<section class="page-layout simple left-sidebar inner-sidebar inner-scroll">
  <div class="uds-page-title" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
    Company Settings
  </div>

  <p *ngIf="!isAdmin">Only the admin user can modify the company settings.</p>

  <div class="uds-page-content-fill dp-white">
    <div class="content fullHeight  p-20" #contentZone fxLayout="column" fxLayoutAlign="start center">

      <div class="center maxWidth1000" *ngIf="organization">
        <section class="account-type">
          <div class="heading-sub-small mb-12">Account Type</div>
          <div class="box p-40">
            <div class="row">
              <div class="col-6">
                <p class="mt-0" *ngIf="organization.organizationNumber">
                  <span class="entity-label">Company # </span>
                  <span class="entity-property"> {{ organization.organizationNumber }} </span>
                </p>
                <p class="mt-0">
                  <span class="entity-label">Account Type </span>
                  <span class="entity-property"> {{ organization.accountType }} </span>
                </p>
                <p>
                  <span class="entity-label">Monthly Limit </span>
                  <span class="entity-property"> {{ !organization.activeContainerLimit? 'Unlimited' : organization.activeContainerLimit }}
                    active
                    shipments </span>
                </p>
                <p class="mb-0">
                  <span class="entity-label">No. of Free Users </span>
                  <span class="entity-property"> Unlimited </span>
                </p>
              </div>
              <div *ngIf="organization.accountType !== 'BASIC'" class="col-6 company-setting-modify easy-form">
                <mat-form-field class="fullWidth" appearance="outline">
                  <mat-label>API Key</mat-label>
                  <input [type]="showApiKey ? 'text' : 'password'" matInput [value]="apiKey" readonly>
                  <mat-icon matSuffix (click)="showApiKey = !showApiKey">{{showApiKey ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <mat-form-field class="fullWidth" appearance="outline">
                  <mat-label>Organization Token</mat-label>
                  <input [type]="showOrganizationKey ? 'text' : 'password'" matInput [value]="organization.organizationKey" readonly>
                  <mat-icon matSuffix (click)="showOrganizationKey = !showOrganizationKey">{{showOrganizationKey ? 'visibility_off' :
                    'visibility'}}</mat-icon>
                </mat-form-field>
                <button class="toRight easy-button" type="submit" mat-flat-button (click)="reGenerateOrganizationToken();">
                  Re-Generate
                </button>
              </div>
            </div>
          </div>
        </section>

        <section class="company-profile-form" *ngIf="!userAccessService.hasNoAccess(accessKeys.COMPANY_SETTING_COMPANY_PROFILE)">
          <div class="heading-sub-small mb-12">Company Profile</div>
          <div class="box p-40"
            *ngIf="!userAccessService.hasAccess(accessKeys.COMPANY_SETTING_COMPANY_PROFILE) || !isAdmin; else companyProfileForm">
            <p>
              <span class="entity-label">Company Name </span>
              <span class="entity-property"> {{ organization.organizationName }} </span>
            </p>
            <p>
              <span class="entity-label">Business Industry </span>
              <span class="entity-property"> {{ organization.industry }} </span>
            </p>
            <p>
              <span class="entity-label">Company Phone </span>
              <span class="entity-property"> {{ organization.phone }} </span>
            </p>
            <p>
              <span class="entity-label">Company Email </span>
              <span class="entity-property"> {{ organization.email }} </span>
            </p>
            <p>
              <span class="entity-label">Address Line 1 </span>
              <span class="entity-property"> {{ organization.streetLine1 }} </span>
            </p>
            <p>
              <span class="entity-label">Address Line 2 </span>
              <span class="entity-property"> {{ organization.streetLine2 }} </span>
            </p>
            <p>
              <span class="entity-label">City </span>
              <span class="entity-property"> {{ organization.city }} </span>
            </p>
            <p>
              <span class="entity-label">State </span>
              <span class="entity-property"> {{ organization.stateProv }} </span>
            </p>
            <p>
              <span class="entity-label">Zip </span>
              <span class="entity-property"> {{ organization.postalCode }} </span>
            </p>
            <p>
              <span class="entity-label">Country </span>
              <span class="entity-property"> {{ getCountryName() }} </span>
            </p>

          </div>
          <ng-template #companyProfileForm>
            <div class="box p-40">
              <form class="easy-form" [formGroup]="f" [autocomplete]="'on'" (ngSubmit)="submit()" *ngIf="f">
                <div fxLayout="row" fxLayoutAlign="start start" #editableZone>
                  <div class="company-setting-modify" style="margin-right: 20px;">
                    <mat-form-field class="fullWidth" appearance="outline">
                      <mat-label>Company Name</mat-label>
                      <input matInput type="text" formControlName="organizationName" name="organizationName" required>
                      <mat-error *ngIf="f.controls && f.controls['organizationName'].invalid">
                        {{ getErrorMessage(f.controls['organizationName']) }}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="fullWidth" appearance="outline">
                      <mat-label>Industry</mat-label>
                      <mat-select formControlName="industry" placeholder="Industry" #industrySelect [disableOptionCentering]="true"
                        panelClass="no-change">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="industryFilterCtrl" placeholderLabel="Search..."
                            noEntriesFoundLabel="'no matching industry found'"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let industry of filteredIndustries | async" [value]="industry.code">{{ industry.name }}
                        </mat-option>
                      </mat-select>

                    </mat-form-field>
                    <mat-form-field class="fullWidth" appearance="outline">
                      <mat-label>Company Phone</mat-label>
                      <input matInput placeholder="Phone No." type="tel" formControlName="phone" name="phone">
                      <mat-error *ngIf="f.controls['phone'].invalid">
                        {{ getErrorMessage(f.controls['phone']) }}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="fullWidth" appearance="outline">
                      <mat-label>Company Email</mat-label>
                      <input matInput placeholder="Company Email" type="email" formControlName="email" name="email">
                      <mat-error *ngIf="f.controls['email'].invalid">
                        {{ getErrorMessage(f.controls['email']) }}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="company-setting-modify">
                    <mat-form-field class="fullWidth" appearance="outline">
                      <mat-label>Street Line 1</mat-label>
                      <input matInput type="text" formControlName="streetLine1" name="streetLine1">
                      <mat-error *ngIf="f.controls['streetLine1'].invalid">
                        {{ getErrorMessage(f.controls['streetLine1']) }}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="fullWidth" appearance="outline">
                      <mat-label>Street Line 2</mat-label>
                      <input matInput type="text" formControlName="streetLine2" name="streetLine2">
                      <mat-error *ngIf="f.controls['streetLine2'].invalid">
                        {{ getErrorMessage(f.controls['streetLine2']) }}</mat-error>
                    </mat-form-field>
                    <div fxLayout="row">
                      <mat-form-field class="halfLeft" appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput type="text" formControlName="userCity" name="userCity">
                        <mat-error *ngIf="f.controls['userCity'].invalid">
                          {{ getErrorMessage(f.controls['userCity']) }}</mat-error>
                      </mat-form-field>
                      <mat-form-field class="halfRight" appearance="outline">
                        <mat-label>State/Province</mat-label>
                        <input matInput type="text" formControlName="stateProv" name="stateProv">
                        <mat-error *ngIf="f.controls['stateProv'].invalid">
                          {{ getErrorMessage(f.controls['stateProv']) }}</mat-error>
                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <mat-form-field class="halfLeft" appearance="outline">
                        <mat-label>Zip/Postal Code</mat-label>
                        <input matInput type="text" formControlName="postalCode" name="postalCode">
                        <mat-error *ngIf="f.controls['postalCode'].invalid">
                          {{ getErrorMessage(f.controls['postalCode']) }}</mat-error>
                      </mat-form-field>
                      <mat-form-field class="halfRight" appearance="outline">
                        <mat-label>Country</mat-label>
                        <mat-select formControlName="countryCode" #countrySelect [disableOptionCentering]="true">
                          <mat-option>
                            <ngx-mat-select-search [formControl]="countryFilterCtrl" placeholderLabel="Search..."
                              noEntriesFoundLabel="'no matching country found'"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let country of filteredCountries | async" [value]="country.country_code">{{
                            country.country_name
                            }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="mb-16">
                  <button class="toRight easy-button" type="submit" [dpBusy]="isFormBusy" mat-flat-button
                    [disabled]="f.invalid || !f.dirty">
                    SAVE CHANGES
                  </button>
                </div>

              </form>
            </div>
          </ng-template>
        </section>

        <!-- show to only enterprise customer -->
        <section class="blockchain">
          <div class="heading-sub-small">Blockchain</div>
          <!--<p class="body-regular">Send a support ticket to enable the Blockchain features for document sharing</p>-->

          <!-- request not sent yet -->
          <ng-container *ngIf="checkDatachainStatusStatus('DISABLED')">
            <!-- <ng-container> -->
            <div class="box p-40" *ngIf="!isAdmin">
              <h3>CargoesDatachain© integration for data sharing hasn't been setup yet. </h3>
            </div>
            <div class="box p-40" *ngIf="isAdmin">
              <p class="body-regular mt-0">
                DP World CargoesDatachain© is a private permissioned blockchain network for organizations to form business consortia, and
                securely
                exchange trusted data among them. If your company is using CargoesDatachain©, you may connect CargoesFlow© with it to enable
                sharing of shipping documents and tracking data.
              </p>
              <div class="components-table-heading mt-20 mb-20">
                Enable CargoesDatachain© integration for data sharing within your business consortium
              </div>

              <mat-error *ngIf="toggleDatachainError">
                {{ toggleDatachainError}}
              </mat-error>
              <form class="easy-form" [formGroup]="blockChainForm" (submit)="submitBlockchainEnableRequest()" fxLayout="row"
                fxLayoutAlign="start start">
                <mat-form-field class="form-field w-300" appearance="outline">
                  <!-- <mat-label> E-mail
                      </mat-label> -->
                  <input email type="email" matInput formControlName="email" placeholder="Cargoes Datachain account email id" required>
                  <mat-error
                    *ngIf="blockChainForm.controls.email.touched || blockChainForm.controls.email.invalid || blockChainForm.controls.email.pattern">
                    <span>Please type a valid email address</span>
                  </mat-error>
                </mat-form-field>
                <button mat-flat-button class="easy-button secondary medium mt-4 ml-20" type="submit"
                  [disabled]="!blockChainForm.valid">REQUEST</button>
              </form>

            </div>
          </ng-container>


          <!-- request was sent -->
          <div class="box p-40" *ngIf="!checkDatachainStatusStatus('DISABLED')">
            <h3 fxLayoutAlign="start center">
              <span>
                Blockchain integration for document sharing
              </span>
              <mat-icon class="ml-4 material-icons-outlined info-icon" tooltip="More info">
                info
              </mat-icon>
            </h3>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                <label class="text-muted">
                  Datachain account
                </label>
                <p>XXXXXXX-XXXXXXXX-XXXXX</p>
              </div>
              <div>
                <label class="text-muted">
                  Status
                </label>
                <p>{{ organization.accountCapabilities.orgDatachainStatus | replace }}</p>
              </div>
              <div>
                <label class="text-muted">
                  Requested on
                </label>
                <p>&nbsp;</p>
              </div>
            </div>

            <!-- if status is pending -->
            <div class="text-right" *ngIf="checkDatachainStatusStatus('ENABLE_PENDING') || checkDatachainStatusStatus('DISABLE_PENDING')">
              <button mat-button color="primary" class="text-uppercase" (click)="revertRequest()">
                <mat-icon>
                  close
                </mat-icon>
                Revert Request
              </button>
            </div>
          </div>
        </section>

        <section class="partners" *ngIf="!userAccessService.hasNoAccess(accessKeys.COMPANY_SETTING_ADD_PARTNERS)">
          <div class="heading-sub-small">Business Partners</div>
          <p class="body-regular">Invite your business partners to CargoesFlow© and share your shipment data with their accounts.</p>

          <!-- <button type="button" mat-icon-button color="primary" aria-label="Add" (click)="openAddPartnerDialog()">
              <mat-icon>add_circle_outline</mat-icon>
              <span> Add a new partner</span>
            </button> -->

          <div class="box p-40">
            <ng-container *ngIf="partnersList?.length > 0">
              <table>
                <tr class="header-row">
                  <th>
                    Company Name
                  </th>
                  <th>
                    Company #
                  </th>
                  <th>
                    Status
                  </th>
                  <th>
                  </th>
                </tr>
                <tr *ngFor="let partner of partnersList;">
                  <td>
                    {{ partner.organizationName }}
                  </td>
                  <td>
                    {{ partner.organizationNumber }}
                  </td>
                  <td [ngClass]="partner.status">
                    {{ partner.status | titlecase }}
                  </td>
                  <td
                    [ngClass]="{'w-112': userAccessService.hasAccess(accessKeys.COMPANY_SETTING_ADD_PARTNERS, null, isAdmin) && isPartnerSender(partner), 'w-216': userAccessService.hasAccess(accessKeys.COMPANY_SETTING_ADD_PARTNERS, null, isAdmin) && isPartnerReceiver(partner)}">
                    <div class="inline-block"
                      tooltip="{{isPartnerActionDisabled(partner) ? 'Your account is not allowed to enable partner. Please contact us for more information.' : null}}"
                      placement="left">
                      <button class="easy-button" mat-flat-button (click)="updatePartner(partner)"
                        *ngIf="userAccessService.hasAccess(accessKeys.COMPANY_SETTING_ADD_PARTNERS, null, isAdmin) && isPartnerSender(partner)"
                        [disabled]="isPartnerActionDisabled(partner)">{{partner.actionLabel}}</button>
                    </div>
                    <span
                      *ngIf="userAccessService.hasAccess(accessKeys.COMPANY_SETTING_ADD_PARTNERS, null, isAdmin) && isPartnerReceiver(partner)">
                      <button class="easy-button mr-16" mat-flat-button (click)="updatePartner(partner, 'accept')">Accept</button>
                      <button class="easy-button" mat-flat-button (click)="updatePartner(partner, 'decline')">Decline</button>
                    </span>
                  </td>
                </tr>
              </table>
            </ng-container>
            <div *ngIf="!partnersList || partnersList.length === 0">
              <p>You don't have partners yet.</p>
            </div>

            <div class="inline-block"
              tooltip="{{isAccountTypeBasic? 'Your account is not allowed to add new partners. Please contact us for more information.' : null}}"
              placement="left">
              <button mat-flat-button class="easy-button secondary add-partner"
                *ngIf="userAccessService.hasAccess(accessKeys.COMPANY_SETTING_ADD_PARTNERS, null, isAdmin)"
                (click)="openAddPartnerDialog(partnersList)" [disabled]="isAccountTypeBasic">
                Add a new partner
              </button>
            </div>

          </div>
        </section>

        <section *ngIf="!userAccessService.hasNoAccess(accessKeys.COMPANY_SETTING_SHIPMENT_DOC)">
          <div class="heading-sub-small">Preferences</div>
          <p class="body-regular">Manage application preferences for your company.</p>

          <div class="box p-40">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div class="heading-sub-small">
                Show shipment documents in shared shipment links
              </div>
              <mat-slide-toggle [checked]="organization.accountPreferences?.showDocumentsInSharedLinks"
                (change)="documentsSliderChange($event)"
                *ngIf="userAccessService.hasAccess(accessKeys.COMPANY_SETTING_SHIPMENT_DOC, null, isAdmin); else template1">
                {{ organization.accountPreferences?.showDocumentsInSharedLinks ? 'On' : 'Off'}}
              </mat-slide-toggle>
              <ng-template #template1>
                <div>{{ organization.accountPreferences?.showDocumentsInSharedLinks ? 'On' : 'Off'}}</div>
              </ng-template>
            </div>

          </div>
        </section>
        <div class="pt-12" *ngIf="!userAccessService.hasNoAccess(accessKeys.DEMURRAGE_CONFIG_AND_HOURS_BEFORE_ARRIVAL)">
          <!-- <ng-template adHost></ng-template> -->
          <dp-notification-metrics></dp-notification-metrics>
        </div>

        <section *ngIf="hasAccessToGeoFence()">
          <div class="heading-sub-small mt-40 mb-12">Inland Locations Geofence in Kilometers</div>
          <div class="box easy-form">
            <mat-form-field appearance="outline" class="w-80">
              <mat-label>Kilometers</mat-label>
              <input matInput type="number" min="2" max="10" [(ngModel)]="geofenceRadius"
                (ngModelChange)="geoFenceRadiusValueChange(geofenceRadius)"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57">
            </mat-form-field>
            <mat-error *ngIf="geofenceRadius < 2 || geofenceRadius > 10">
              Geofence value is invalid. It must be between 2 and 10 Kilometers</mat-error>
          </div>
        </section>

        <section class="pt-12" *ngIf="authService.currentUserValue.isMetaInfoEnabled">
          <div class="heading-sub-small mb-12">Shipment Custom Parameters</div>
          <div class="box p-40"
            *ngIf="!isAdmin; else scpForm" style="margin-bottom: 100px;">
            <div *ngFor="let label of shipMetaInfo; let i = index">
              <p *ngIf="label">
                <span class="entity-label">Label{{i + 1}}</span>
                <span class="entity-property">{{ shipMetaInfo[i] }}</span>
              </p>
            </div>
          </div>
          <ng-template #scpForm>
            <div class="box p-40">
              <form class="easy-form" [formGroup]="fg" [autocomplete]="'on'" (ngSubmit)="submitMetaInfo()" *ngIf="fg">
                <ng-container *ngFor="let label of shipMetaInfo; let i = index">
                  <div *ngIf="i % 3 === 0" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                      <mat-form-field class="halfWidth" appearance="outline" *ngIf="shipMetaInfo.length > i else blankTemplate">
                        <mat-label>Label{{i + 1}}</mat-label>
                        <input matInput type="text" [formControlName]="'Label' + (i + 1)" [name]="'Label' + (i + 1)" maxlength="100" />
                      </mat-form-field>
                      <mat-form-field class="halfWidth" appearance="outline" *ngIf="shipMetaInfo.length > i + 1 else blankTemplate">
                        <mat-label>Label{{i + 2}}</mat-label>
                        <input matInput type="text" [formControlName]="'Label' + (i + 2)" [name]="'Label' + (i + 2)" maxlength="100" />
                      </mat-form-field>
                      <mat-form-field class="halfWidth" appearance="outline" *ngIf="shipMetaInfo.length > i + 2 else blankTemplate">
                        <mat-label>Label{{i + 3}}</mat-label>
                        <input matInput type="text" [formControlName]="'Label' + (i + 3)" [name]="'Label' + (i + 3)" maxlength="100" />
                      </mat-form-field>
                  </div>
                </ng-container>
                <ng-template #blankTemplate>
                  <div class="halfWidth"></div>
                </ng-template>
                <div class="mb-16">
                  <button class="toRight easy-button" type="submit" [dpBusy]="isFormBusy" mat-flat-button
                    [disabled]="fg.invalid || !fg.dirty">
                    SAVE
                  </button>
                </div>
              </form>
            </div>
          </ng-template>
        </section>

      </div>

    </div>
  </div>
</section>