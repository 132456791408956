import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ACCOUNT_TYPES, LoginCompanyStatusType, STATES, User } from '../user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginSsoService {
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

  authorizeLogin() {
    const state = this.route.snapshot.queryParamMap.get('state');
    const sessionState = this.route.snapshot.queryParamMap.get('session_state');
    const code = this.route.snapshot.queryParamMap.get('code');
    const redirectUrl = `${environment.baseUrl}${environment.SSORedirectUri}`;
    const params = {
      state,
      sessionState,
      code,
      redirectUrl,
    };

    localStorage.setItem('MawaniToken', code);

    this.ssoLoginUserContext(params);
  }

  ssoLoginUserContext(params: any) {
    const state = params.state ? JSON.parse(atob(params.state)) : {};
    const { url: redirectUrl } = state;

    this.authService.ssoLoginUser(params).subscribe(
      (response) => {
        const user = response.body as User;
        if (user.companyStatus != LoginCompanyStatusType.VERIFIED) {
          this.authService.setupNewUser(user, response);
          this.ssoLogoutUser('set-company');
        } else {
          this.authService.setupNewUser(user, response);
          if (!user.systemAdminPanelAccess && user.state != STATES.VERIFIED) {
            this.ssoLogoutUser('login');
          }
          this.authService.getOrganization().subscribe((v2) => {
            const user = response.body as User;
            user.accountType = v2.accountType;
            user.accountCapabilities = v2.accountCapabilities;
            user.organizationPartnerships = v2.organizationPartnerships;
            this.authService.setupNewOrganization(v2);
            this.authService.setupNewUser(user, response);
            // setupNewUser is called again here due to the getOrganization call. Will make this
            // return response;
            if (user.systemAdminPanelAccess) {
              this.pushEventToDatalayer();
              this.router.navigate(['/admin']);
            } else if (user.state === STATES.VERIFIED) {
              this.pushEventToDatalayer();
              this.router.navigate(['/dashboard']);
            }
          });
        }
      },
      (errorResponse: HttpErrorResponse) => {
        console.log(errorResponse);
        this.ssoLogoutUser();
      }
    );
  }

  pushEventToDatalayer() {
    window['dataLayer'] = window['dataLayer'] || [];

    if (window['dataLayer'].filter((event) => event.event === environment.gaEvents.actions.signInCargoes).length === 0) {
      window['dataLayer'].push({
        eventDetail: null,
      });
      const event = environment.gaEvents.actions.signInCargoes; // 'dl_signin'
      const eventDetail = {
        eventCategory: environment.gaEvents.categories.login, // 'login'
        eventAction: environment.gaEvents.status.successful, // 'successful'
      };

      this.authService.logEvent(event, eventDetail, true);
    }
  }
  login() {
    const ssoUrl = new URL(`${environment.ssoAuthServer}${environment.SSOAuthUri}`);
    const state = '';

    ssoUrl.searchParams.append('state', state);
    ssoUrl.searchParams.append('client_id', environment.clientId);
    ssoUrl.searchParams.append('response_type', 'code');
    ssoUrl.searchParams.append('redirect_uri', `${environment.baseUrl}${environment.SSORedirectUri}`);

    window.location.href = ssoUrl.toString();
  }

  private ssoLogoutUser(redirectUri = ``): void {
    console.log('ssoLogoutUser', environment.baseUrl);
    window.location.href = `${environment.ssoAuthServer}${environment.SSOLogoutUri}${environment.baseUrl}${redirectUri}&client_id=${environment.clientId}`;
  }

  ssoLogout(redirectUri = ``) {
    // sso logout
    console.log('logoutData');
    // localStorage.removeItem(environment.dpwStorageKeys.currentUser);
    // localStorage.removeItem(environment.dpwStorageKeys.dpwUserType);
    // localStorage.removeItem(environment.dpwStorageKeys.token);
    // if (localStorage.getItem(environment.dpwStorageKeys.actingUser)) {
    //   localStorage.removeItem(environment.dpwStorageKeys.actingUser);
    // }
    // if (localStorage.getItem(environment.dpwStorageKeys.dpwUserOrg)) {
    //   localStorage.removeItem(environment.dpwStorageKeys.dpwUserOrg);
    // }
    // if (localStorage.getItem(environment.dpwStorageKeys.dpwOrgSelectedUser)) {
    //   localStorage.removeItem(environment.dpwStorageKeys.dpwOrgSelectedUser);
    // }

    setTimeout(() => {
      this.ssoLogoutUser(redirectUri);
    }, 100);
  }
}
