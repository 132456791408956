<div class="fw-wrapper" [hidden]="!showWidget()">
  <div class="fw-container">
    <dp-svg class="close-icon" [src]="SvgMap.closeIcon" w="14" (click)="onCrossClick()"></dp-svg>
    <div class="btn-container">
      <p class="header">We value your feedback.</p>
      <button mat-flat-button class="easy-button" (click)="onRateUs()">Rate Us</button>
    </div>
  </div>
</div>

<dpw-feedback-widget
  *ngIf="showFeedbackWidget"
  [userEmail]="authService?.currentUserValue?.userEmail"
  [isLoggedIn]="isLoggedIn"
  [tenantId]="authService.currentUserValue.orgCode"
  [tenantName]="authService.currentUserValue.organizationName"
  [environmentType]="environmentType"
  [heading]="currentPageFeedbackMetadata?.header"
  [ratingStyle]="currentPageFeedbackMetadata?.ratingsStyle"
  [description]="currentPageFeedbackMetadata?.subHeader"
  [thankYouHeading]="currentPageFeedbackMetadata?.thankYouScreenHeading"
  (onClose)="toggleFeedback()"
  [thankYouDescription]="currentPageFeedbackMetadata?.thankYouScreenSubHeading"
  [enableProgressive]="currentPageFeedbackMetadata?.enableProgressiveLoad"
  [progressiveChips]="currentPageFeedbackMetadata?.progressiveChipsList"
  [badRatingHeader]="currentPageFeedbackMetadata?.progressiveChipsHeader?.badRatingHeader"
  [averageRatingHeader]="currentPageFeedbackMetadata?.progressiveChipsHeader?.avgRatingHeader"
  [feedbackBtnText]="currentPageFeedbackMetadata?.feedbackButtonLabel"
  [failureHeading]="currentPageFeedbackMetadata?.failureHeader"
  [failureDescription]="currentPageFeedbackMetadata?.failureDescription"
  [feedbackWidgetMetadataId]="currentPageFeedbackMetadata?.id"
  [goodRatingHeader]="currentPageFeedbackMetadata?.progressiveChipsHeader?.goodRatingHeader"
  [textareaLimit]="1000"
  (onSuccess)="onSuccessCallback()"
>
</dpw-feedback-widget>
