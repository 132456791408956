import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PlaygroundComponent } from './playground/playground.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { SettingsRoutingModule } from './settings/settings-routing.module';
import { SuperAdminGuard } from './auth/super-admin.guard';
import { BrowserModule } from '@angular/platform-browser';
import { AccessPermissionGuard } from './auth/access-permission.guard';
import { NotFoundComponent } from './not-found.component';
import { WelcomeGuard } from './auth/welcome.guard';

const routes: Routes = [
  { path: 'playground', component: PlaygroundComponent, data: { title: 'Playground' } },
  // { path: 'faq', component: FaqComponent, data: { title: 'FAQ' } },
  // { path: 'feedback', component: FeedBackComponent, data: { title: 'FEEDBACK' } },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'container-shipments',
    loadChildren: () => import('./ocean-shipment-tabs/ocean-shipment-tabs.module').then((m) => m.OceanShipmentTabsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'ocean-shipments',
    data: { title: 'Ocean shipments' },
    loadChildren: () => import('./new-ocean-shipment/ocean-shipment.module').then((m) => m.OceanShipmentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'road-shipments',
    loadChildren: () => import('./road-shipment/road-shipment.module').then((m) => m.RoadShipmentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'insights-reports',
    loadChildren: () => import('./insights-reports/insights-reports.module').then((m) => m.InsightsReportsModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  // },
  {
    path: 'container-pickup',
    loadChildren: () => import('./delivery-board2/delivery-board.module').then((m) => m.DeliveryBoardModule2),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'purchase-orders',
  //   loadChildren: () => import('./explorer/po/po.module').then((m) => m.PurchaseOrderModule),
  // },
  // {
  //   path: 'customer-orders',
  //   loadChildren: () => import('./explorer/po/po.module').then((m) => m.PurchaseOrderModule),
  //   canActivate: [AuthGuard]
  // },
  // // {
  //   path: 'shipments',
  //   loadChildren: () => import('./shipments2/shipments2.module').then((m) => m.Shipments2Module),
  // },
  {
    path: 'upload-history',
    loadChildren: () => import('./upload-history/upload-history.module').then((m) => m.UploadHistoryModule),
    canActivate: [AuthGuard, AccessPermissionGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./super-admin-tabs/super-admin-tabs.module').then((m) => m.SuperAdminTabsModule),
    canLoad: [SuperAdminGuard],
  },
  {
    path: '_tracking', // group shipment share
    loadChildren: () => import('./tracking/tracking.module').then((m) => m.TrackingModule),
    canActivate: [],
  },
  {
    path: '_tracking_shipment',
    data: { title: 'Tracking Shipment' },
    loadChildren: () => import('./tracking-shipment/tracking-shipment.module').then((m) => m.TrackingShipmentModule),
  },
  {
    path: 'truck-shipments',
    loadChildren: () => import('./truck-shipments/truck-shipments.module').then((m) => m.TruckShipmentsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'documents',
    loadChildren: () => import('./documents/documents.module').then((m) => m.DocumentsModule),
    canActivate: [],
  },
  {
    path: 'tracking-by-group',
    loadChildren: () => import('./tracking-by-group/tracking-by-group.module').then((m) => m.TrackingByGroupModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'widget',
    data: { title: 'Widget' },
    loadChildren: () => import('./dp-widget/dp-widget.module').then((m) => m.DpWidgetModule),
  },
  {
    path: 'air-shipments',
    data: { title: 'Air shipments' },
    loadChildren: () => import('./air-shipment/air-shipment.module').then((m) => m.AirShipmentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'outsourced-logistics',
    data: { title: 'OUTSOURCED-LOGISTICS' },
    loadChildren: () => import('./outsourced-logistics/ol.module').then((m) => m.OLShipmentModule),
  },
  {
    path: 'invoices',
    data: { title: 'Invoice' },
    loadChildren: () => import('./invoices/invoices.module').then((m) => m.InvoicesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    data: { title: 'Dashboard' },
    loadChildren: () => import('./board/board.module').then((m) => m.Dashboard2Module),
    canActivate: [AuthGuard],
  },
  {
    path: 'product-master',
    data: { title: 'Product Master' },
    loadChildren: () => import('./product-master/bootstrap.module').then((m) => m.BootstrapModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'advance-consent',
    data: { title: 'Advance Consent' },
    loadChildren: () => import('./advance-consent/advance-consent.module').then((m) => m.AdvanceConsentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'orders',
    data: { title: 'Purchase Order' },
    loadChildren: () => import('./purchase-orders/purchase-orders.module').then((m) => m.PurchaseOrdersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'purchase-orders',
    data: { title: 'Customer Orders' },
    loadChildren: () => import('./orders-advanced/orders-advanced.module').then((m) => m.OrdersAdvancedModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'flight-schedules',
    data: { title: 'Flight Schedules' },
    loadChildren: () => import('./flight-schedules/flight-schedules.module').then((m) => m.FlightSchedulesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'vessel-schedules',
    data: { title: 'Vessel Schedules' },
    loadChildren: () => import('./vessel-schedules/vessel-schedules.module').then((m) => m.VesselSchedulesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar',
    loadChildren: () => import('./schedule-container/schedule-container.module').then((m) => m.ScheduleContainerModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'performance',
    loadChildren: () => import('./performance-container/performance-container.module').then((m) => m.PerformanceContainerModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'users-teams',
    loadChildren: () => import('./users-teams/users-teams.module').then((m) => m.UsersTeamsModule),
    canActivate: [AuthGuard, AccessPermissionGuard],
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then((m) => m.WelcomeModule),
    canActivate: [WelcomeGuard],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }), BrowserModule], //TODO: performance improvement
  exports: [RouterModule, SettingsRoutingModule, AuthRoutingModule],
  providers: [AuthGuard, SuperAdminGuard],
})
export class AppRoutingModule {}
