import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { UsersService } from 'app/settings/users/users.service';
import { UIService, ProgressService, ProgressRef } from 'app/shared';
import { delay, finalize, takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Country, Organization, Industry } from 'app/settings/users/users.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { StaticDataService } from '@dp/services/static-data.service';
import { AuthService } from 'app/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PartnersService } from 'app/shared/services/partners/partners.service';
import { MatDialog } from '@angular/material/dialog';
import { AddPartnerDialogComponent } from 'app/settings/add-partner-dialog/add-partner-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NmApiService } from 'app/settings/notification-metrics/nm-service';
import { CompanySettingService } from './company-setting.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ACCOUNT_TYPES } from '../../../auth/user.model';
import { ReGenerateOrganizationToken } from 'app/auth/auth-data.model';
import { UserAccessService } from 'app/auth/user-access.service';
import { USER_ACCESS } from 'app/user-access.constants';

enum PageModeType {
  loading,
  display,
  edit,
}

@Component({
  selector: 'dp-company-setting',
  templateUrl: './company-setting.component.html',
  styleUrls: ['./company-setting.component.scss'],
  animations: dpAnimations,
})
export class CompanySettingComponent implements OnInit, OnDestroy {
  showLogo = false;
  PageModeType = PageModeType;
  pageMode = PageModeType.display;
  isFormBusy = false;
  isAdmin: boolean;
  notEditableBlocker: ProgressRef;
  isAccountTypeBasic = false;
  shipMetaInfo = [];
  f: UntypedFormGroup;
  fg: UntypedFormGroup;
  organization: Organization = null;
  partnersList = null;
  countries: Country[];
  industries: Industry[];
  public countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public industryFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  public filteredIndustries: ReplaySubject<Industry[]> = new ReplaySubject<Industry[]>(1);
  protected _onDestroy = new Subject<void>();

  @ViewChild('countrySelect', { static: true }) countrySelect: MatSelect;
  @ViewChild('industrySelect', { static: true }) industrySelect: MatSelect;

  @ViewChild('contentZone', { static: true }) contentZone?: ElementRef;
  @ViewChild('uploadZone') uploadZone?: ElementRef;
  @ViewChild('editableZone', { static: true }) editableZone?: ElementRef;

  toggleDatachainError = '';
  productionHosting = environment.productionHosting;
  apiKey = environment.publicApiValue;
  showApiKey = false;
  showOrganizationKey = false;
  accessKeys = USER_ACCESS;
  geofenceRadius = null;

  // blockchain email form
  blockChainForm = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.pattern(environment.regex.emailValidation)])],
  });

  constructor(
    private staticDataService: StaticDataService,
    private authService: AuthService,
    private usersService: UsersService,
    private uiService: UIService,
    private progressService: ProgressService,
    private formBuilder: UntypedFormBuilder,
    private partnersService: PartnersService,
    private dlg: MatDialog,
    private nmApiService: NmApiService,
    private companySettingService: CompanySettingService,
    private cdr: ChangeDetectorRef,
    public userAccessService: UserAccessService
  ) {
    this.isAccountTypeBasic = this.authService.currentUserValue.accountType === ACCOUNT_TYPES.BASIC;
  }

  ngOnDestroy(): void {
    if (this.notEditableBlocker) this.progressService.detach(this.notEditableBlocker);
  }

  ngOnInit(): void {
    this.isAdmin = this.authService.currentUserValue.isAdmin;

    // this.partnersList = this.authService.currentUserValue.organizationPartnerships;

    this.processStaticData();
    this.getMetaInfo();
    const progressRef = this.progressService.showProgress(this.contentZone);
    this.authService
      .getOrganization()
      .pipe(
        // delay(200),
        finalize(() => {
          this.progressService.detach(progressRef);
        })
      )
      .subscribe(
        (setting) => {
          this.organization = setting;
          this.partnersList = setting.organizationPartnerships;
          if (
            Array.isArray(setting.organizationProperties) &&
            setting.organizationProperties.length &&
            setting.organizationProperties[0].propertyKey
          ) {
            this.geofenceRadius = setting.organizationProperties[0].propertyValue;
          }
          this.updatePartnershipAction();
          this.buildForm();
          this.nmApiService.update({ notificationMetrics: setting.notificationMetrics });
        },
        () => {
          this.uiService.showSnackbar("We can't process this right now. Please try again later.", null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      );
  }

  getMetaInfo() {
    const progressRef = this.progressService.showProgress(this.contentZone);
    this.usersService
      .getOrganizationMetaInfo()
      .pipe(
        finalize(() => {
          this.progressService.detach(progressRef);
        })
      )
      .subscribe(
        (shipmentMetaInfo) => {
          this.shipMetaInfo = shipmentMetaInfo;
          this.buildMetaForm();
        },
        () => {
          this.uiService.showSnackbar("We can't process this right now. Please try again later.", null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      );
  }

  resendPartner(organizationNumber) {
    this.companySettingService.createPartnership(organizationNumber).subscribe(
      (response: any) => {
        const companyAdded = response[0];
        if (companyAdded.status === 'error') {
          this.uiService.showSnackbar(companyAdded.description, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        } else {
          this.uiService.showSnackbar('Invitation is resent successfully!', null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'accent',
          });
        }
      },
      (error) => {
        if (error.status === 400) {
          this.uiService.showSnackbar(`Company Id - ${organizationNumber} not found`, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        } else {
          this.uiService.showSnackbar(`Failed to send invite to partner. Please try again later.`, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      }
    );
  }

  updatePartner(partner, receiverAction?) {
    const status = receiverAction ? receiverAction : partner.actionLabel.toLowerCase();
    if (status === 'resend') {
      this.resendPartner(partner.organizationNumber);
    } else if (['enable', 'disable'].some((action) => status === action)) {
      this.openConfirmDialog(status, partner, receiverAction);
    } else if (['accept', 'decline'].some((action) => status === action)) {
      this.updatePartnerCall(partner, receiverAction, status);
    }
  }
  updatePartnerCall(partner, receiverAction, status) {
    this.companySettingService.updatePartnerStatus(partner.id, status).subscribe(
      (success) => {
        const actionStatus = this.updatePartnerStatus(partner, receiverAction);
        this.uiService.showSnackbar(`Partnership status is ${actionStatus} successfully!`, null, {
          duration: environment.snackBarDuration.success,
          panelClass: 'accent',
        });
      },
      (error) => {
        if (error.status === 400) {
          this.uiService.showSnackbar(`Failed to update status. Please refresh page and try again!`, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        } else {
          this.uiService.showSnackbar(`Failed to ${status} partner.`, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      }
    );
  }

  openConfirmDialog(status, partner, receiverAction) {
    let message = '';
    let dialogData;
    if (status === 'enable') {
      message = `Are you sure you want to re-enable the partner?`;
      dialogData = new ConfirmDialogModel('Please confirm to enable the partner', message);
    } else if (status === 'disable') {
      message = `Disabling this partner won't affect the shared shipments in your partner account. You will no longer be able to share future shipments with this partner after it gets disabled`;
      dialogData = new ConfirmDialogModel('Please confirm to disable the partner', message);
    }
    const dialogRef = this.dlg.open(ConfirmDialogComponent, {
      maxWidth: '500px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.updatePartnerCall(partner, receiverAction, status);
      }
    });
  }

  updatePartnerStatus(partner, receiverAction?) {
    let status = '';
    switch (partner.status.toLowerCase()) {
      case 'active': {
        partner.status = 'DISABLED';
        partner.actionLabel = 'Enable';
        status = 'disabled';
        break;
      }
      case 'disabled': {
        partner.status = 'PENDING';
        partner.actionLabel = 'Resend';
        status = 'updated';
        break;
      }
      case 'pending': {
        if (receiverAction === 'accept') {
          partner.status = 'ACTIVE';
          partner.actionLabel = 'Disable';
          status = 'accepted';
        } else {
          this.partnersList.splice(
            this.partnersList.findIndex((p) => p.id === partner.id),
            1
          );
          status = 'declined';
        }
        break;
      }
    }
    return status;
  }
  updatePartnershipAction(): string {
    return this.partnersList.map((partner) => {
      switch (partner.status.toLowerCase()) {
        case 'declined':
        case 'pending': {
          partner.actionLabel = 'Resend';
          break;
        }
        case 'disabled': {
          partner.actionLabel = 'Enable';
          break;
        }
        case 'active': {
          partner.actionLabel = 'Disable';
          break;
        }
      }
    });
  }
  isPartnerSender(partner): boolean {
    if (
      (partner.status === 'PENDING' &&
        partner.latestUpdateByOrganizationId &&
        this.organization.id === partner.latestUpdateByOrganizationId) ||
      partner.status === 'ACTIVE' ||
      partner.status === 'DISABLED'
    ) {
      return true;
    }
    return false;
  }
  isPartnerReceiver(partner): boolean {
    if (
      partner.status === 'PENDING' &&
      partner.latestUpdateByOrganizationId &&
      this.organization.id !== partner.latestUpdateByOrganizationId
    ) {
      return true;
    }
    return false;
  }

  isPartnerActionDisabled(partner): boolean {
    return partner.status === 'DISABLED' && this.isAccountTypeBasic;
  }

  editMode() {
    this.pageMode = PageModeType.edit;
  }

  getIndustryName(): string {
    const industries = this.staticDataService.getStaticDataDirect().industries;
    const industryName = industries.find((industry) => industry.code === this.organization.industry)?.['name'];
    return industryName || 'N/A';
  }
  getCountryName(): string {
    const countries = this.staticDataService.getStaticDataDirect()['countries'];
    const countryName = countries.find((country) => country['country_code'] === this.organization.countryCode)?.['country_name'];
    return countryName || 'N/A';
  }

  buildForm() {
    this.f = this.formBuilder.group({
      organizationName: [this.organization.organizationName, Validators.required],
      industry: [this.organization.industry],
      phone: [this.organization.phone, Validators.pattern(environment.validators.phone)],
      email: [this.organization.email, [Validators.email]],
      streetLine1: [this.organization.streetLine1],
      streetLine2: [this.organization.streetLine2],
      userCity: [this.organization.city],
      stateProv: [this.organization.stateProv],
      postalCode: [this.organization.postalCode],
      countryCode: [this.organization.countryCode],
    });

    if (!this.isAdmin) {
      this.f.disable();
      this.notEditableBlocker = this.progressService.showProgress(this.editableZone, true);
    }
  }

  buildMetaForm() {
    const formGroupConfig = {};
    this.shipMetaInfo?.forEach((label, index) => {
      formGroupConfig[`Label${index + 1}`] = [this.shipMetaInfo[index]];
    });
    this.fg = this.formBuilder.group(formGroupConfig);
    if (!this.isAdmin) {
      this.fg.disable();
      this.notEditableBlocker = this.progressService.showProgress(this.editableZone, true);
    }
  }

  getErrorMessage(control: UntypedFormControl) {
    return control.hasError('required') ? 'You must enter a value' : control.hasError('pattern') ? 'This is not a valid phone number' : '';
  }

  fileChangeEvent(event) {
    const progressRef = this.progressService.showProgress(this.uploadZone);

    const formData: FormData = new FormData();
    formData.append('fileName', event.target.files.item(0), event.target.files.item(0).name);
    formData.append('uploadType', 'company_logo');

    this.usersService
      .uploadLogoFile(formData)
      .pipe(
        delay(2000),
        finalize(() => this.progressService.detach(progressRef))
      )
      .subscribe(
        (result) => {},
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: environment.snackBarDuration.warning, panelClass: 'warn' });
        }
      );
  }

  submit() {
    this.isFormBusy = true;
    const payload: Partial<Organization> = {
      organizationName: this.f.value.organizationName?.trim(),
      industry: this.f.value.industry,
      phone: this.f.value.phone,
      email: this.f.value.email,
      streetLine1: this.f.value.streetLine1,
      streetLine2: this.f.value.streetLine2,
      city: this.f.value.userCity,
      stateProv: this.f.value.stateProv,
      postalCode: this.f.value.postalCode,
      countryCode: this.f.value.countryCode,

      //todo:
      //logo: this.organization.logo,
    };

    this.usersService
      .updateOrganization(payload)
      .pipe(
        finalize(() => {
          this.isFormBusy = false;
        })
      )
      .subscribe(
        (result) => {
          this.organization = { ...this.organization, ...result };
          this.pageMode = PageModeType.display;
          this.authService.setupNewOrganization(this.organization);
          this.uiService.showSnackbar('Setting is saved!', null, { duration: environment.snackBarDuration.success, panelClass: 'accent' });
        },
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: environment.snackBarDuration.warning, panelClass: 'warn' });
        }
      );
  }
  submitMetaInfo() {
    this.isFormBusy = true;
    const payloadForShipmentCustom = [];
    this.shipMetaInfo.forEach((label, index) => {
      const value = this.fg.value[`Label${index + 1}`];
      if (value && value.trim() !== '') {
        payloadForShipmentCustom.push(value);
      }
    });
    this.usersService
      .updateOrganizationMetaInfo(payloadForShipmentCustom)
      .pipe(
        finalize(() => {
          this.isFormBusy = false;
        })
      )
      .subscribe(
        (result) => {
          // TODO: build the function auth service organization meta lable. 
          this.authService.currentUserValue.organizationMetaLabels = [...result];
          const metaInfo = result;
          while (metaInfo.length < 9) {
              metaInfo.push('');
          }
          this.shipMetaInfo = metaInfo;
          this.pageMode = PageModeType.display;
          this.authService.setupNewOrganization(this.organization);
          this.uiService.showSnackbar('Setting is saved!', null, { duration: environment.snackBarDuration.success, panelClass: 'accent' });
        },
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: environment.snackBarDuration.warning, panelClass: 'warn' });
        }
      );
  }

  /**
   * Sets the initial value after the filteredCountries are loaded initially
   */
  processStaticData() {
    this.countries = this.staticDataService.getStaticDataDirect()['countries'];
    this.filteredCountries.next(this.countries.slice());
    // listen for search field value changes
    this.countryFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCountries();
    });

    this.industries = this.staticDataService.getStaticDataDirect()['industries'];
    this.filteredIndustries.next(this.industries.slice());
    // listen for search field value changes
    this.industryFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterIndustries();
    });
  }

  protected filterCountries() {
    if (!this.countries) {
      return;
    }
    // get the search keyword
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCountries.next(this.countries.filter((country) => country.country_name.toLowerCase().indexOf(search) > -1));
  }
  protected filterIndustries() {
    if (!this.industries) {
      return;
    }
    // get the search keyword
    let search = this.industryFilterCtrl.value;
    if (!search) {
      this.filteredIndustries.next(this.industries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredIndustries.next(this.industries.filter((industry) => industry.name.toLowerCase().indexOf(search) > -1));
  }

  asIsOrder() {
    return 1;
  }

  submitBlockchainEnableRequest() {
    const payload = {
      enable: true,
      email: this.blockChainForm.get('email').value,
    };

    this.toggleDatachainError = '';
    this.usersService.toggleDatachain(payload).subscribe(
      (result) => {
        if (result && result.success) {
          this.uiService.showSnackbar('Successfully sent request.', null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'accent',
          });

          // update new dataChainStatus
          if (result.orgDatachainStatus) {
            this.organization.accountCapabilities.orgDatachainStatus = result.orgDatachainStatus;
          }
        }
      },
      (httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse && httpErrorResponse.error && httpErrorResponse.error.errorCode == '40030') {
          this.toggleDatachainError = 'A request is already in Pending';
        } else {
          this.toggleDatachainError = 'There was something wrong. Please try again.';
        }
      }
    );
  }

  isOrgDatachainStatusEnabled(): boolean {
    if (this.organization && this.organization.accountCapabilities) {
      return (
        this.organization.accountCapabilities.orgDatachainStatus === 'ENABLED' ||
        this.organization.accountCapabilities.orgDatachainStatus === 'ENABLE_PENDING'
      );
    }
    // DISABLED | DISABLE_PENDING
    return false;
  }

  checkDatachainStatusStatus(status: string): boolean {
    if (this.organization && this.organization.accountCapabilities) {
      return this.organization.accountCapabilities.orgDatachainStatus == status;
    }
    // DISABLED | DISABLE_PENDING
    return false;
  }

  isEnterpriseCustomer() {
    return this.organization && this.organization.accountType === 'ENTERPRISE';
  }

  revertRequest() {
    const payload = {
      enable: false,
      revertRequest: true,
    };

    this.toggleDatachainError = '';
    this.usersService.toggleDatachain(payload).subscribe(
      (result) => {
        if (result && result.success) {
          this.uiService.showSnackbar('Successfully revert request.', null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'accent',
          });

          // update new dataChainStatus
          if (result.orgDatachainStatus) {
            this.organization.accountCapabilities.orgDatachainStatus = result.orgDatachainStatus;
          }
        }
      },
      (httpErrorResponse: HttpErrorResponse) => {
        this.uiService.showSnackbar('Failed to revert request', null, {
          duration: environment.snackBarDuration.warning,
          panelClass: 'warn',
        });
      }
    );
  }

  getPartners(): Observable<any> {
    return this.partnersService.getPartners();
  }

  openAddPartnerDialog(partnersList): void {
    // close setting dialog ?
    this.dlg.open(AddPartnerDialogComponent, {
      width: '448px',
      data: {
        partners: partnersList,
      },
    });
  }

  documentsSliderChange(state: MatSlideToggleChange) {
    this.organization.accountPreferences.showDocumentsInSharedLinks = state.checked;
    const payload = {
      showDocumentsInSharedLinks: state.checked,
    };
    this.usersService
      .toggleIncludeDocuments(payload)
      .pipe(finalize(() => (this.isFormBusy = false)))
      .subscribe(
        (result) => {
          this.uiService.showSnackbar('Setting is saved!', null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'accent',
          });
        },
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: environment.snackBarDuration.warning, panelClass: 'warn' });
          this.organization.accountPreferences.showDocumentsInSharedLinks = !state.checked;
        }
      );
  }

  reGenerateOrganizationToken() {
    const progressRef = this.progressService.showProgress(this.contentZone);
    this.authService
      .reGenerateOrganizationToken()
      .pipe(
        finalize(() => {
          this.progressService.detach(progressRef);
        })
      )
      .subscribe(
        (response: ReGenerateOrganizationToken) => {
          this.organization.organizationKey = response.organizationToken;
          this.cdr.detectChanges();
        },
        () => {
          this.uiService.showSnackbar("We can't process this right now. Please try again later.", null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      );
  }

  geoFenceRadiusValueChange(value) {
    if (value < 2 || value > 10) return;
    this.companySettingService.updateOrganizationProperties(value).subscribe(
      (response) => {
        this.uiService.showSnackbar('Saved successfully', null, {
          duration: environment.snackBarDuration.success,
          panelClass: 'accent',
        });
      },
      () => {
        this.uiService.showSnackbar("We can't process this right now. Please try again later.", null, {
          duration: environment.snackBarDuration.error,
          panelClass: 'warn',
        });
      }
    );
  }

  hasAccessToGeoFence(): boolean {
    return (
      this.authService.isAdmin() &&
      this.authService.currentUserValue.accountCapabilities?.isTruckShipmentTrackingAllowed &&
      this.authService.currentUserValue.accountCapabilities?.isTruckShipmentsUploadAllowed
    );
  }
}
