export const USER_ACCESS = {
  TAG: 'tag',
  FLAG: 'flag',
  NOTES: 'notes',
  ADD_USER: 'addUser',
  EXPORT_DATA: 'exportData',
  MANAGE_USERS: 'manageUsers',
  LINK_TO_SHARE: 'linkToShare',
  POD_FILES: 'podFiles',
  ADD_SHIPMENT_TAB_VISIBILITY: 'addShipments:tab',
  ADD_SHIPMENT_ADD_SHIPMENT: 'addShipments:addShipments',
  ADD_SHIPMENT_UPLOAD_HISTORY: 'addShipments:uploadHistory',
  DEMURRAGE_CONFIG_AND_HOURS_BEFORE_ARRIVAL: 'notificationMetrics',
  SHIPMENT_UPDATE: 'shipments:update',
  SHIPMENT_DELETE: 'shipments:delete',
  MARK_AS_COMPLETE: 'markAsComplete',
  SHIPMENT_ADD_PRODUCT_OR_ORDER: 'shipments:addProductOrOrder',
  SHIPMENT_EDIT_PRODUCT_OR_ORDER: 'shipments:editProductOrOrder',
  SHIPMENT_REMOVE_PRODUCT_OR_ORDER: 'shipments:deleteProductOrOrder',
  SHIPMENT_MULTI_SELECT_BULK_ACTION: 'shipments:multiSelectBulkAction',
  SHIPMENT_REPORT_TRACKING_ERROR: 'shipments:reportTrackingError',
  SHIPMENT_SHARE_WITH_PARTNER: 'shipments:shareWithPartner',
  SHIPMENT_MARK_AS_COMPLETE: 'shipments:update',
  SHIPMENT_ADD_CARRIER_NAME: 'shipments:addCarrierName',
  DOCUMENTS_PREVIEW: 'documents:preview',
  DOCUMENTS_UPLOAD: 'documents:upload',
  DOCUMENTS_DOWNLOAD: 'documents:download',
  DOCUMENTS_DELETE: 'documents:delete',
  BASIC_ORDER_CREATE_NEW: 'ordersBasic:createOrder',
  BASIC_ORDER_EDIT: 'ordersBasic:editOrder',
  BASIC_ORDER_DELETE: 'ordersBasic:deleteOrder',
  ADVANCED_ORDER_ADD: 'ordersAdvanced:add',
  ADD_PRODUCT: 'addProduct',
  EDIT_PRODUCT: 'editProduct',
  REMOVE_PRODUCT: 'removeProduct',
  COMPANY_SETTING_COMPANY_PROFILE: 'companySetting:companyProfile',
  COMPANY_SETTING_ADD_PARTNERS: 'companySetting:addingPartners',
  COMPANY_SETTING_SHIPMENT_DOC: 'companySetting:shipmentdocuments',
  COMPANY_SETTING_SHIPMENT_CUSTOM_PARAM: 'companySetting:shipmentMetaInfoParameters',
  OCEAN_SHIPMENTS_MARK_AS_COMPLETE: 'oceanShipments:markAsComplete',
  ROAD_SHIPMENTS_MARK_AS_COMPLETE: 'roadShipments:markAsComplete',
  ROAD_SHIPMENTS_UPDATE_VEHICLE_DRIVER: 'roadShipments:updateVehicleAndDriverPhone',
};

export const ACCESS_TYPE = {
  VIEW: 'VIEW',
  HIDE: 'HIDE',
  ALL: 'ALL',
};

export const ROUTE_PATH_ACCESS = {
  'upload-history': USER_ACCESS.ADD_SHIPMENT_UPLOAD_HISTORY,
  'add-shipments/:shipmentType': USER_ACCESS.ADD_SHIPMENT_ADD_SHIPMENT,
  'add-shipments-one-by-one/:shipmentType': USER_ACCESS.ADD_SHIPMENT_ADD_SHIPMENT,
  users: USER_ACCESS.MANAGE_USERS,
};
