import { Component, Input } from '@angular/core';
import { ISharedContainer } from 'app/tracking/tracking.model';
import { Utility } from '@dp/utilities';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';

// similar to container-info component that is used in tracking share
@Component({
  selector: 'dp-container-details',
  templateUrl: './container-details.component.html',
  styleUrls: ['./container-details.component.scss'],
})
export class ContainerDetailsComponent  {
  SvgMap = SvgMap;
  @Input() container: any;

  getVesselNameNumber(container): string {
    return Utility.getVesselNameNumber(container);
  }
}
