import { Component, Input } from '@angular/core';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { RoadPayload } from 'app/shared/shared.model';

@Component({
  selector: 'dp-road-shipment-details',
  templateUrl: './road-shipment-details.component.html',
  styleUrls: ['./road-shipment-details.component.scss'],
})
export class RoadShipmentDetailsComponent {
  SvgMap = SvgMap;
  @Input() payload: RoadPayload;
}
