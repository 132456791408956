import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { CompanySignupStatus } from 'app/auth/user.model';
import { Utility } from '@dp/utilities';
import { OrganizationSearch } from 'app/settings/users/users.model';

@Component({
  selector: 'dp-org-list',
  templateUrl: './org-list.component.html',
  styleUrls: ['./org-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrgListComponent implements OnInit {
  Utility = Utility;
  @Input() organizations: OrganizationSearch[];
  @Output() selected = new EventEmitter<OrganizationSearch>();

  organizationSelected?: OrganizationSearch = null;
  constructor() {}

  ngOnInit(): void {
    console.log('inside the org list', this.organizations);
    if (this.organizations) {
      this.itemClicked(this.organizations[0]);
    }
  }

  itemClicked(organization: OrganizationSearch) {
    this.organizationSelected = organization;
    this.selected.emit(this.organizationSelected);
  }
}
