<section class="tracking-info p-32">
  <div *ngIf="errorText" class="mt-12">
    <mat-error>{{errorText}}</mat-error>
  </div>

  <div class="mt-12" *ngIf="payload">

    <div class="relative">
      <dp-road-shipment-details [payload]="payload"></dp-road-shipment-details>
      <dp-svg class="reportIcon" tooltip="Report error" [src]="SvgMap.report" w="20" (click)="reportError()"></dp-svg>
    </div>
    <div *ngIf="!showReportError" @slideInLeft>
      <mat-tab-group style="overflow-y: auto;" (selectedTabChange)="selectedTabChange($event)">

        <mat-tab [label]="tabs[0]">
          <section class="tab-content">
            <div *ngIf="transportJourneys">
              <div class="truck-summary" *ngIf="transportJourneys.roadTransport; let roadTransport">
                <h3>Tracking Details</h3>
                <div class="row">
                  <dp-address [address]="roadTransport.originAddressComponents" [addressType]="'Origin'"></dp-address>
                  <dp-address [address]="roadTransport.destinationAddressComponents" [addressType]="'Destination'"></dp-address>
                </div>

                <div class="row mt-8">
                  <div class="halfWidth">
                    <span class="propertyCaption">{{roadTransport.ata ? 'ATD': 'ETD'}} </span>
                    <span class="value">{{roadTransport.atd ? roadTransport.atd : roadTransport.etd}}</span>
                  </div>
                  <div class="halfWidth">
                    <span class="propertyCaption">{{roadTransport.ata ? 'ATA': 'ETA'}} </span>
                    <span class="value">{{roadTransport.ata ? roadTransport.ata : roadTransport.eta}}</span>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="showTemperature && payload.temperatureInfo as info">
              <dp-temperature-humidity [temperatureInfo]="info" style="display:block;"></dp-temperature-humidity>
            </div>

            <ng-container *ngIf="shipmentMap">
              <h3>Tracking Events</h3>
              <dp-shipment-map [shipmentDetail]="shipmentMap" [maxZoom]="15"></dp-shipment-map>
            </ng-container>

            <p class="latest-updated" *ngIf="payload.trackingUpdatedAt">
              <label class="text-muted">Last updated: </label>
              <strong><span class="value">{{ payload.trackingUpdatedAt | dateAgo }}</span></strong>
            </p>

            <div *ngIf="transportJourneys?.roadTransport?.statusSteps?.steps; let steps" class="mt-12 pl-12">
              <dp-tracking-step [steps]="steps"></dp-tracking-step>
            </div>
          </section>
        </mat-tab>

        <mat-tab *ngIf="showDocumentsInSharedLinks">
          <ng-template mat-tab-label>
            Documents
            <ng-container *ngIf="payload?.documents?.length !== 0">
              <div [ngClass]="{'active': selectedTabIndex === 1, 'miniFab' : payload?.documents?.length}">
                {{ payload?.documents?.length }}</div>
            </ng-container>
          </ng-template>


          <section class="tab-content">
            <!-- No document cases -->
            <div *ngIf="payload.documents.length == 0" fxLayout="column" fxLayoutAlign="center center">
              <h2 class="text-muted">No documents available!</h2>
              <!-- <img src="assets/images/svg/no-result-found.svg" /> -->
            </div>

            <ng-container *ngIf="payload.documents.length > 0">
              <dp-documents-upload-card-public *ngFor="let document of payload.documents" [document]="document"
                [entityType]="EntityType.TRACKING_SHIPMENT" [tokenId]="tokenId">
              </dp-documents-upload-card-public>
            </ng-container>
          </section>
        </mat-tab>

        <mat-tab *ngIf="showException && !isEmpty(payload.exceptions)">
          <ng-template mat-tab-label>
            Exceptions
            <div *ngIf="exceptionCount" class="miniFab" [ngClass]="{'active': selectedTabIndex === 2}">
              {{ exceptionCount }}</div>
          </ng-template>
          <section class="tab-content mt-8">
            <dp-os-exception [shipmentDetail]="payload" [steps]="payload.exceptions.steps"></dp-os-exception>
          </section>
        </mat-tab>

      </mat-tab-group>

    </div>

    <dp-report-error *ngIf="showReportError" [isEmail]="true" @slideInRight (goBack)="showReportError=false" (close)="showReportError=false" [data]="payload"
      [commentOnly]="true">
    </dp-report-error>
  </div>
</section>