import { BrowserModule, Title } from '@angular/platform-browser';
import { ApplicationRef, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { MainNavComponent } from './navigation/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { PlaygroundComponent } from './playground/playground.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { AuthService } from './auth/auth.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { ProgressContainerComponent } from './shared/progress-container/progress-container.component';
import { DynamicOverlay } from './shared/progress-container/overlay/dynamic-overlay.service';
import { DynamicOverlayContainer } from './shared/progress-container/overlay/dynamic-overlay-container.service';
import { ProgressService } from './shared/progress-container/progress.service';
import { UIService } from './shared/ui.service';
import { DpModule } from '@dp/dp.module';
import { dpConfig } from './dpConfig';
import { BasicAuthInterceptor } from './auth/basic-auth.interceptor';
import { AuthModule } from './auth/auth.module';
import { WelcomeModule } from './welcome/welcome.module';
import { DpComponentsModule } from '@dp/components/components.module';
import { SettingsModule } from './settings/settings.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDialogModule } from '@angular/material/dialog';
import { GeocodeService } from '@dp/services/geocode.service';
import { DpDialogComponent } from './shared/dp-dialog/dp-dialog.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { MdePopoverModule } from '@material-extended/mde';
import { DpDirectivesModule } from '@dp/directives/directives.module';
import { UserPasswordEditComponent } from './navigation/header/user-password-edit/user-password-edit.component';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MultiLinesSnackbarComponent } from '@dp/components/multi-line-snackbar/multi-line-snackbar.component';
import { AppLoadModule } from './app-load.module';
import { CountdownModule } from 'ngx-countdown';
import { WelcomeProfileDlgComponent } from './navigation/header/welcome-profile-dlg/welcome-profile-dlg.component';
import { InviteUserPopoverComponent } from './settings/user2/invite-user-popover/invite-user-popover.component';
import { VideoDlgComponent } from './navigation/header/video-dlg/video-dlg.component';
import { SnackbarTemplateComponent } from './navigation/header/snackbar-template/snackbar-template.component';
import { BlankContainerComponent } from './shared/progress-container/blank-container.component';
import { WebUpdateComponent } from './navigation/header/web-update/web-update.component';
import { WebUpdateService } from './shared/webUpdate.service';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './in-memory-data.service';
import { environment } from 'environments/environment';
import { DpPipesModule } from '@dp/pipes/pipes.module';
import { CookieService } from 'ngx-cookie-service';
import { AddPartnerDialogComponent } from './settings/add-partner-dialog/add-partner-dialog.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { DecimalPipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { DpWorldFaqModule } from 'dp-world-faq';
import { NotificationDlgComponent } from './navigation/header/notification/notification-dlg/notification-dlg.component';
import { EventDetailComponent } from './navigation/header/notification/event-tab/event-detail/event-detail.component';
import { SystemTabComponent } from './navigation/header/notification/system-tab/system-tab.component';
import { EventTabComponent } from './navigation/header/notification/event-tab/event-tab.component';
import { AccountTabComponent } from './navigation/header/notification/account-tab/account-tab.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ItemListComponent } from './navigation/header/notification/event-tab/event-detail/item-list/item-list.component';
import { TcsDialogComponent } from './tcs-dialog/tcs-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SelectAutocompleteModule } from '../@dp/components/select-autocomplete/select-autocomplete.module';
import { SharedApiService } from './shared/shared.service';
import { FeedbackWidgetComponent } from './shared/components/feedback-widget/feedback-widget.component';
import { DpwFeedbackWidgetModule } from '@dpw/feedback-widget';
import { GlobalSearchComponent } from './navigation/header/global-search/global-search.component';
import { AccessPermissionGuard } from './auth/access-permission.guard';
import { NgChartsModule } from 'ng2-charts';
import { ChatbotComponent } from './shared/components/chatbot/chatbot.component';
import { ChatbotMessageComponent } from './shared/components/chatbot-message/chatbot-message.component';

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    PlaygroundComponent,
    HeaderComponent,
    SidenavListComponent,
    ProgressContainerComponent,
    BlankContainerComponent,
    DpDialogComponent,
    UserPasswordEditComponent,
    MultiLinesSnackbarComponent,
    WelcomeProfileDlgComponent,
    InviteUserPopoverComponent,
    VideoDlgComponent,
    SnackbarTemplateComponent,
    WebUpdateComponent,
    AddPartnerDialogComponent,
    TcsDialogComponent,
    NotificationDlgComponent,
    EventDetailComponent,
    SystemTabComponent,
    EventTabComponent,
    AccountTabComponent,
    ItemListComponent,
    GlobalSearchComponent,
    FeedbackWidgetComponent,
    ChatbotComponent,
    ChatbotMessageComponent,
  ],
  imports: [
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppLoadModule,
    MaterialModule,
    LayoutModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    NgChartsModule,
    OverlayModule,
    SettingsModule,
    AuthModule,
    DpComponentsModule,
    WelcomeModule,
    LazyLoadImageModule,
    ReactiveFormsModule,
    DpModule.forRoot(dpConfig),
    FontAwesomeModule,
    MdePopoverModule,
    DpDirectivesModule,
    DpPipesModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule.forRoot({ exclude: ['/playground'] }),
    InlineSVGModule.forRoot(),
    environment.production
      ? []
      : InMemoryWebApiModule.forRoot(InMemoryDataService, {
          delay: 500,
          passThruUnknownUrl: true,
        }),
    CountdownModule,
    DpWorldFaqModule,
    InfiniteScrollModule,
    PdfViewerModule,
    SelectAutocompleteModule,
    DpwFeedbackWidgetModule,
  ],

  providers: [
    AuthService,
    AccessPermissionGuard,
    UIService,
    SharedApiService,
    WebUpdateService,
    ProgressService,
    GeocodeService,
    DynamicOverlay,
    DynamicOverlayContainer,
    CookieService,
    Title,
    DecimalPipe,
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private applicationRef: ApplicationRef) {
    // uncomment this to track change detection cycles.
    //this.tickTracker();
  }

  /**
   * this code allows us to examing perf problems related to slow angular change detections cycles
   * to enable this, just add to the construction of app.module.ts :    this.tickTracker();
   */
  tickTracker() {
    const originalTick = this.applicationRef.tick;

    let numChangeDetectionCalls = 0;
    let totalTime = 0;
    let currentTime = performance.now();
    let lastTime = performance.now();

    this.applicationRef.tick = function () {
      let returnValue = null;

      numChangeDetectionCalls++;

      // works, but no average
      const before = performance.now();
      returnValue = originalTick.apply(this, arguments);
      const after = performance.now();

      const duration = after - before;

      totalTime += duration;

      lastTime = currentTime;
      currentTime = after;
      const sinceLastCall = currentTime - lastTime;

      if (sinceLastCall > 500) {
        console.log(
          'avg CD time: ' +
            totalTime / numChangeDetectionCalls +
            '   Total CD: ' +
            numChangeDetectionCalls +
            '   Total CD Time: ' +
            totalTime
        );
      }

      console.log('Change Detection; duration: ' + duration + ' total calls: ' + numChangeDetectionCalls);
      return returnValue;
    };
  }
}

// list of supported locales
// registerLocaleData(localeGB);
// registerLocaleData(localeCA); //canada
// registerLocaleData(localeAU);

// platformBrowserDynamic().bootstrapModule(AppModule);
