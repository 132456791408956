<div id="register" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

  <dp-intro fxFlex></dp-intro>

  <div id="register-form-wrapper" dpPerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
    <div id="register-form">
      <div class="logo" fxHide.gt-xs [routerLink]="'/'">
        <img src="assets/cargoflow-new-square.svg">
      </div>

      <div class="title">CREATE AN ACCOUNT</div>

      <form fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" #f="ngForm" (ngSubmit)="onSubmit(f)">
        <mat-form-field>
          <input type="email" matInput placeholder="Your email" ngModel name="email" email required #emailInput="ngModel">
          <mat-error *ngIf="emailInput.hasError('required')">Field must not be empty.</mat-error>
          <mat-error *ngIf="!emailInput.hasError('required')">Invalid email address</mat-error>
        </mat-form-field>
        <mat-form-field hintLabel="Should be at least 6 characters long.">
          <input type="password" matInput placeholder="Your password" ngModel name="password" required minlength="6" #pwInput="ngModel">
          <mat-hint align="end">{{ pwInput.value?.length }} / 6</mat-hint>
          <mat-error *ngIf="pwInput.value?.length < 6">Has to be at least 6 characters long.</mat-error>
        </mat-form-field>
        <mat-form-field hintLabel="Verify password.">
          <input type="password" matInput placeholder="Verify password" ngModel name="password2" required pattern="{{ pwInput.value }}" #pwInput2="ngModel">
          <mat-error>Passwords do not match.</mat-error>
        </mat-form-field>
        <mat-checkbox ngModel name="agree" required color="primary">Agree to Terms and Conditions.</mat-checkbox>
        <button type="submit" mat-raised-button [disabled]="f.invalid" class="submit-button primary-800">
          <span *ngIf="isBusy || isBusy" class="spinner-border spinner-border-sm mr-1"></span>
          Register</button>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      </form>
      <div class="register" fxLayout="column" fxLayoutAlign="center center">
        <span class="text">Already have an account? </span>
        <a class="link" [routerLink]="'/login'">Login here</a>
      </div>
    </div>
  </div>
</div>
