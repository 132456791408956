import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsersService } from 'app/settings/users/users.service';
import { UIService } from 'app/shared';
import { environment } from 'environments/environment';
import { delay, finalize } from 'rxjs';
import { UtilsService } from '../../../../@dp/services/utils.service';
import { Team } from 'app/settings/settings.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { noWhitespaceValidator } from '@dp/validators/no-whitespaces.validator';
import { noLeadingTrailingSpacesValidator } from '@dp/validators/no-leading-trailing-spaces.validator';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dp-create-edit-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss'],
})
export class CreateEditTeamComponent implements OnInit {
  f: FormGroup;
  isBusy = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Team,
    private utilsService: UtilsService,
    private service: UsersService,
    private dialogRef: MatDialogRef<CreateEditTeamComponent>,
    private uiService: UIService
  ) {}
  ngOnInit() {
    this.f = new FormGroup({
      teamName: new FormControl(this.data ? this.data.teamName : '', [Validators.required, noLeadingTrailingSpacesValidator]),
      teamDescription: new FormControl(this.data ? this.data.teamDescription : ''),
    });
  }

  submit(event: Event) {
    event.preventDefault(); // Prevent the default form submission behavior of closing the dialog
    this.isBusy = true;
    const teamName = this.f.value.teamName;
    const result$ = this.data ? this.service.updateTeam(this.data.id, this.f.value) : this.service.createTeam(this.f.value);
    result$
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe({
        next: (v) => {
          this.dialogRef.close(this.f.value);
          const msg = this.data ? `Team ${teamName} is updated successfully!` : `A new team ${teamName} is created!`;
          this.uiService.showSnackbar(msg, null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'success',
          });
          if (this.modifyMode()) {
            this.data.teamName = this.f.value.teamName;
            this.data.teamDescription = this.f.value.teamDescription;
          } else {
            this.utilsService.reloadPage();
          }
        },
        error: (e) => {
          if (e.status === 409) {
            this.uiService.showSnackbar(`Team name is already taken. Please try another name`, null, {
              duration: environment.snackBarDuration.error,
              panelClass: 'warn',
            });
          } else {
            this.dialogRef.close();
            this.uiService.showSnackbar(`Failed to create a team. Please try later`, null, {
              duration: environment.snackBarDuration.error,
              panelClass: 'warn',
            });
          }
        },
      });
  }

  modifyMode(): boolean {
    return this.data ? true : false;
  }

  closeDialog(): void {
    console.log('Closing dialog...');
    this.dialogRef.close();
  }
}
