import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
  Inject,
  Renderer2,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { interval, of, Subscription } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';
import { DOCUMENT } from '@angular/common';
import { UIService } from 'app/shared/ui.service';
import { Router, NavigationEnd } from '@angular/router';
import { ApplicationSettings, EditModeType } from './header.model';
import { MdePopoverTrigger } from '@material-extended/mde';
import { User, LoginCompanyStatusType, STATES, WebUpdate, WebUpdateTypes, ACCOUNT_TYPES } from 'app/auth/user.model';
import { Name } from 'app/shipments2/shipments2.model';
import { environment } from 'environments/environment';
import { Utility } from '@dp/utilities';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { WelcomeProfileDlgComponent } from './welcome-profile-dlg/welcome-profile-dlg.component';
import { VideoDlgComponent } from './video-dlg/video-dlg.component';
import { SnackbarTemplateComponent } from './snackbar-template/snackbar-template.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UsersService } from 'app/settings/users/users.service';
import { WebUpdateService } from 'app/shared/webUpdate.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LoginSsoService } from 'app/auth/login-sso/login-sso.service';
import { NotificationService } from './notification/notification.service';
import { startWith, switchMap } from 'rxjs/operators';
import { dpAnimations } from '@dp/animations';
import { TcsDialogComponent } from 'app/tcs-dialog/tcs-dialog.component';
import { LogoMode } from '@dp/components/logo/logo.component';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { Organization } from 'app/settings/users/users.model';
import { UserAccessService } from 'app/auth/user-access.service';
import { USER_ACCESS } from 'app/user-access.constants';
import { UtilsService } from '@dp/services/utils.service';
// import { SettingsComponentDialog } from 'app/settings/settings-dialog/settings-dialog.component';

@UntilDestroy()
@Component({
  selector: 'dp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: dpAnimations,
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  SvgMap = SvgMap;
  fileSrc: any = {};
  LoginCompanyStatusType = LoginCompanyStatusType;
  environment = environment;
  @Output() sidenavToggle = new EventEmitter<void>();
  isAuth: boolean;
  Name = Name;
  remindMeRef: MatSnackBarRef<SnackbarTemplateComponent> = null;

  currentUser: User;
  initials: string;
  bannerWebUpdates: WebUpdate[] = null;
  // _EditModeType = EditModeType;
  editMode = EditModeType.NONE;
  isHandsetSubscription: Subscription;
  countrySelected: string;
  applicationSettings: ApplicationSettings = { darkMode: false };
  visible = false;
  hasNotification = false;
  isHandset: boolean;
  isProd = environment.productionHosting;
  notificationBeatSub: Subscription;
  enableBeta = environment.enableBeta;
  showSettings = false;
  orgCode: string;
  LogoMode = LogoMode;
  ACCOUNT_TYPES = ACCOUNT_TYPES;
  accessKeys = USER_ACCESS;
  isAccountPlus;
  @ViewChildren(MdePopoverTrigger) trigger: QueryList<MdePopoverTrigger>;
  @ViewChild('profileTrigger') profileTrigger: MdePopoverTrigger;
  @ViewChild('notificationTrigger') notificationTrigger: MdePopoverTrigger;
  @Output() flipExpand = new EventEmitter<void>();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private authService: AuthService,
    private usersService: UsersService,
    private renderer: Renderer2,
    private uiService: UIService,
    private webUpdateService: WebUpdateService,
    private router: Router,
    private dlg: MatDialog,
    private snackBar: MatSnackBar,
    private gaService: GoogleAnalyticsService,
    private loginSsoService: LoginSsoService,
    private notificationService: NotificationService,
    public userAccessService: UserAccessService,
    private utilsService: UtilsService
  ) {}
  ngAfterViewInit(): void {
    // if (environment.enableBeta) {
    //   console.warn('remove next line');
    //   // this.trigger.toArray()[1].openPopover();
    // } else {
    //   console.warn('remove next line');
    // }
  }

  getOrgPublicLogo() {
    const impersonated = this.authService.currentUserValue?.impersonated ? true : false;
    this.fileSrc = {
      url: environment.rootUrl + 'admin/organizations/logo',
      httpHeaders: {
        'x-dpw-apikey': environment.apiValue,
        'X-DPW-User-Token': this.authService.currentUserValue.token,
        [environment.HEADER.IMPERSONATED_TOKEN]: impersonated,
      },
    };
  }

  ngOnInit() {
    this.uiService.isHandsetChanged.subscribe((result) => (this.isHandset = result));
    this.authService.currentUser.pipe(untilDestroyed(this)).subscribe((user) => {
      this.isAuth = this.authService.isAuth();
      if (this.isAuth) {
        let org = Utility.getItemDecrypted(environment.storage.currentOrg) || (null as Organization);
        if (org && org.hasOrgLogo) {
          //  Utility.setItemEncrypted(environment.storage.currentOrg, organization);
          this.getOrgPublicLogo();
        } else {
          this.fileSrc = {};
        }
      } else {
        this.fileSrc = {};
      }
      this.orgCode = user?.orgCode || '';
      this.isAccountPlus = user?.accountType === ACCOUNT_TYPES.PLUS;
      this.currentUser = user;
      this.initials = Utility.getInitials(this.currentUser);
      if (this.shouldShowRemindMe()) {
        this.showRemindMe();
      } else if (this.remindMeRef) {
        this.remindMeRef.dismiss();
        this.remindMeRef = null;
      } else if (this.shouldShowTC()) {
        this.showTC();
      }
      if (!this.isAuth) {
        this.stopWebUpdates();
        if (this.notificationBeatSub) {
          this.notificationBeatSub.unsubscribe();
          this.notificationBeatSub = null;
        }
      }
    });
    this.countrySelected = 'us';
    // TODO: switch to enum laster
    const countriesWithoutUS = ['gb', 'cn', 'us'];
    const newCountry = countriesWithoutUS.find((country) => this.document.location.pathname.startsWith('/' + country + '/'));
    this.countrySelected = newCountry ? newCountry : this.countrySelected;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // event is an instance of NavigationEnd, get url!
        const url = event.urlAfterRedirects;
        const routes = environment.noHeaderRoutes as string[];
        if (routes.find((route) => url.indexOf(route) > 0)) {
          this.visible = false;
        } else {
          this.visible = true;
          if (this.shouldShowWelcomeDlg()) {
            console.log('opening dialog', this.currentUser);
            this.dlg.open(WelcomeProfileDlgComponent, { disableClose: true, width: '520px' });
          } else if (this.shouldShowRemindMe()) {
            this.showRemindMe();
          } else if (this.shouldGetWebUpdates()) {
            this.getWebUpdates();
          }
          if (this.authService.isAuth()) {
            this.checkNotificationStatus();
          }
        }
      }
    });

    let localSettings = JSON.parse(localStorage.getItem(environment.storage.applicationSettings));
    if (localSettings !== null) {
      this.applicationSettings = localSettings;
    }

    this.setTheme();
    // const oneHour = 1000 * 60 * 60;
    // // * 100 to bypass timer for now
    // timer(0, oneHour * 100) //first call is immediately
    //   .pipe(
    //     untilDestroyed(this),
    //     switchMap(() => {
    //       return this.usersService.getUserWebUpdates();
    //     })
    //   )
    //   .subscribe((webUpdates: WebUpdate[]) => {
    //     const tutorial = webUpdates.find((webUpdate) => webUpdate.type === WebUpdateTypes.TUTORIAL);
    //     this.showTutorial(tutorial);
    //     this.bannerWebUpdates = webUpdates.filter((webUpdate) => webUpdate.type === WebUpdateTypes.BANNER);

    //     if (this.bannerWebUpdates.length) {
    //       this.webUpdateService.showChainedSnackbars(this.bannerWebUpdates);
    //     }
    //     // from(this.bannerWebUpdates)
    //     //   .pipe(timed(1000))
    //     //   .subscribe((webUpdate) => {
    //     //     this.snackBar.openFromComponent(WebUpdateComponent, {
    //     //       data: webUpdate,
    //     //       verticalPosition: 'bottom',
    //     //       panelClass: 'footer-snack',
    //     //     });
    //     //   });
    //   });

    // console.warn('remove next line');
    // this.openCompanySettingsDlg();
  }

  private checkNotificationStatus() {
    if (this.notificationBeatSub) {
      this.notificationBeatSub.unsubscribe();
      this.notificationBeatSub = null;
    }
    this.notificationBeatSub = interval(environment.heartBeats.notificationBeat * 1000)
      .pipe(
        startWith(10000),
        switchMap(() => {
          if (this.authService.isAuth()) {
            return this.notificationService.getCounts();
          } else {
            return of({ exception: 0, event: 0, system: 0 });
          }
        })
      )
      .subscribe((result) => {
        this.hasNotification = result.exception || result.event || result.system ? true : false;
      });
  }

  private showRemindMe() {
    this.remindMeRef = this.snackBar.openFromComponent(SnackbarTemplateComponent, {
      data: 'Your email address is not verified, please confirm your email!',
      verticalPosition: 'bottom',
      panelClass: 'footer-snack',
    });
  }

  private shouldGetWebUpdates() {
    let webUpdatesHandled = Utility.getLocalStorageObjectValue(environment.storage.currentSession, environment.storage.webUpdatesHandled);
    return !webUpdatesHandled && this.authService.isAuth();
  }
  getWebUpdates() {
    this.usersService
      .getUserWebUpdates()
      .pipe(untilDestroyed(this))
      .subscribe(
        (webUpdates: WebUpdate[]) => {
          const tutorial = webUpdates.find((webUpdate) => webUpdate.type === WebUpdateTypes.TUTORIAL);
          this.showTutorial(tutorial);
          this.bannerWebUpdates = webUpdates.filter((webUpdate) => webUpdate.type === WebUpdateTypes.BANNER);

          if (this.bannerWebUpdates.length) {
            this.webUpdateService.showChainedSnackbars(this.bannerWebUpdates);
          }
          Utility.addToLocalStorageObject(environment.storage.currentSession, environment.storage.webUpdatesHandled, true);
        },
        (error) => {
          //no need to show it on the ui
          console.warn('getWebUpdates failed', error);
        }
      );
  }
  stopWebUpdates() {
    this.webUpdateService.stop();
  }

  private showTutorial(webUpdate: WebUpdate) {
    if (!webUpdate) {
      return;
    }
    setTimeout(() => {
      this.playVideo();
      this.usersService
        .setUserWebUpdate({
          webUpdateId: webUpdate.id,
          isFinished: true,
        })
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          //no action here
        });
    }, 1000);
  }

  showSABackBtn() {
    return localStorage.getItem(environment.storage.superAdminUser);
  }
  showSAPageLinkBtn() {
    return this.currentUser?.systemAdminPanelAccess;
  }

  goToSAPage() {
    if (this.currentUser?.systemAdminPanelAccess) {
      if (this.currentUser?.impersonated) {
        this.router.navigate(['/admin'], { queryParams: { orgSuperAdmin: true } });
      } else {
        this.router.navigate(['/admin']);
      }
    }
  }

  backToSA() {
    this.authService.backToSA();
  }

  private shouldShowWelcomeDlg(): boolean {
    // console.error('mike, clean up here');
    // return true;
    return (
      this.authService.isAuth() &&
      this.currentUser &&
      this.currentUser.state === STATES.VERIFIED &&
      this.currentUser.companyStatus === LoginCompanyStatusType.VERIFIED &&
      this.currentUser.showUserInformationConfirmationDialog === true
    );
  }

  //todo: unit test needed
  private shouldShowRemindMe() {
    let remindMeLater = Utility.getLocalStorageObjectValue(environment.storage.currentSession, 'remindMeLater');
    return this.authService.isAuth() && this.authService.currentUserValue.pendingUserEmail && !remindMeLater;
  }

  private shouldShowTC(): boolean {
    const hasOpenDlg = this.dlg.openDialogs && this.dlg.openDialogs.length > 0;
    return !hasOpenDlg && this.authService.normalUserOnNormalPage() && this.authService.currentUserValue.userAcceptedTcs === false;
  }

  private showTC() {
    const config: MatDialogConfig = {
      height: '80%',
      width: '80%',
      closeOnNavigation: false,
      disableClose: true,
      hasBackdrop: true,
      backdropClass: 'backdropBackground',
    };

    const dialogRef = this.dlg.open(TcsDialogComponent, config);
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        return true;
      }
    });
  }

  ngOnDestroy() {}

  // onToggleSidenav() {
  //   this.sidenavToggle.emit();
  // }

  switchCountry() {
    const newUrl = this.document.location.origin + '/' + (this.countrySelected === 'us' ? '' : this.countrySelected);
    this.document.location.href = newUrl;
  }

  onLogout() {
    this.authService.logout();
  }

  ngDarkModeChange() {
    this.setTheme();
  }

  setTheme() {
    if (this.applicationSettings.darkMode) {
      this.renderer.addClass(document.body, 'theme-dark');
      this.renderer.removeClass(document.body, 'theme-default');
    } else {
      this.renderer.addClass(document.body, 'theme-default');
      this.renderer.removeClass(document.body, 'theme-dark');
    }

    localStorage.setItem(environment.storage.applicationSettings, JSON.stringify(this.applicationSettings));
  }

  closeProfile() {
    this.profileTrigger.closePopover();
    this.editMode = EditModeType.NONE;
  }

  closeInviteUsers() {
    this.trigger.toArray()[0].closePopover();
  }

  popoverOpened() {
    this.editMode = EditModeType.NONE;
  }

  editModeChanged(editMode: EditModeType) {
    this.editMode = editMode;
  }

  update(user: User) {
    console.log('user updated from edit component', user);
    this.editMode = EditModeType.NONE;
    this.currentUser = user;
    this.authService.userUpdated(user);
  }

  closeHelp() {
    // this.trigger.toArray()[1].closePopover();
  }

  closeNotification() {
    this.showSettings = false;
    this.notificationTrigger.closePopover();
  }
  toggle() {
    this.notificationTrigger.closePopover();
    this.navigateTo('settings');
    this.router.navigate(['/settings'], { queryParams: { tab: 'notifications' } });
  }

  onCloseNotificationDlg() {
    this.showSettings = false;
    console.log('onCloseNotificationDlg');
  }

  contactUs() {
    this.closeHelp();
    this.router.navigate(['help/feedback']);
  }

  playVideo() {
    this.closeHelp();
    let dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'video-dlg-container';

    dialogConfig = { ...dialogConfig, ...this.getDlgDimension() };

    this.dlg.open(VideoDlgComponent, dialogConfig);
  }

  getDlgDimension() {
    let width = window.innerWidth * 0.8;
    let height = window.innerHeight * 0.8;
    if ((width * 9) / 16 > height) {
      width = (height * 16) / 9;
    } else {
      height = (width * 9) / 16;
    }
    return { width: width + 'px', height: height + 'px' };
  }

  gotoCompanySettings() {
    this.navigateTo('company-settings');
  }
  gotoUserSettings() {
    this.navigateTo('settings');
  }

  openManageUserDlg() {
    this.router.navigate(['users']);
  }

  openFAQ() {
    if (this.isAccountPlus) {
      this.router.navigate(['help/faq']);
    } else {
      Utility.redirectTo('cargoes/flow/help-center/faq');
    }
  }

  navigateTo(page) {
    const url = this.utilsService.migrateRouteHandler(page);
    !!url && this.router.navigate([url]);
  }

  logout() {
    if (environment.SSO && environment.production) {
      this.loginSsoService.ssoLogout();
    }
    this.authService.logout();
  }
  getManageUsersTeamsLabel(): string {
    return this.authService.isTeamFeatureEnabled ? 'Manage Users & Teams' : 'Manage Users';
  }
}
