<div fxLayout="column" class="full-height">
  <dp-header (sidenavToggle)="drawer.toggle()" (flipExpand)="isExpanded = !isExpanded"></dp-header>
  <mat-sidenav-container fxFlex autosize>
    <mat-sidenav #drawer class="sidenav primary" fixedInViewport fixedTopGap="57" [attr.role]="isHandset ? 'dialog' : 'navigation'"
      [mode]="isHandset ? 'over' : 'side'" role="navigation" [opened]="isHandset === false && isAuth && showSideNav">
      <div fxLayout="column" fxLayoutAlign="space-between" class="full-height">
        <dp-sidenav-list (closeSidenav)="drawer.close()" [isExpanded]="isExpanded"></dp-sidenav-list>

        <div fxLayout="column" fxLayoutAlign="space-between" class='cursor-pointer collapse-container' (click)='toggleSideBar()' [tooltip]="isExpanded ? '' : 'Expand'" placement="right" tooltipClass="themeTooltip">
          <mat-divider class="line full-width"></mat-divider>
          <div class='expansion' [ngClass]="isExpanded ? 'expanded' : 'non-expanded'">
            <div *ngIf='isExpanded else nonExpanded'>
              <span [setSVGAttributes]="svgAttributes" [inlineSVG]="SvgMap.expandRev"></span>
              COLLAPSE
            </div>
            <ng-template #nonExpanded>
              <span [setSVGAttributes]="svgAttributes" [inlineSVG]="SvgMap.expand"></span>
            </ng-template>
          </div>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-container">
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <footer *ngIf='!isFooterHide' fxLayout="row" fxLayoutAlign="center center"  [ngClass]="{'sidenav-not-expand': !isExpanded, 'sidenav-expand': isExpanded}">
    <span>Powered by DP World</span>
  </footer>
  <dp-feedback-widget [isLoggedIn]="isAuth"></dp-feedback-widget>
</div>
