import { environment } from 'environments/environment';
import { SideNavLabel } from './nav.constant';
import { ROUTE_PATH } from 'app/route.constants';
const isProdOrStaging = environment.environmentType === 'staging' || environment.environmentType === 'prod';

export class NavItem {
  uri: string;
  label: string;
  svg: string;
  matIcon: string;
  showInProd: boolean;
  matMenu: string;

  constructor(uri: string, label: string, svgPath: string, matIcon: string = null, isProd: boolean = false, matMenu: string = null) {
    this.uri = uri;
    this.label = label;
    this.showInProd = isProd;
    this.matMenu = matMenu;
    if (svgPath) {
      this.svg = svgPath;
    }
    if (matIcon) {
      this.matIcon = matIcon;
    }
  }
}
export class SideNavBarItem {
  hasChild: boolean;
  navItem: NavItem;
  children?: SideNavBarItem[];
  isShown?: boolean;
  constructor(hasChild: boolean, navItem: NavItem, children?: SideNavBarItem[], isShown?: boolean) {
    this.hasChild = hasChild;
    this.navItem = navItem;
    this.children = children;
    this.isShown = isShown;
  }
}

export enum AddContainerByTypes {
  ByContainer,
  ByBooking,
  ByMBL,
  ByVessel,
  ByAir,
  ByRoad,
  ByBulk,
  ByOL,
  ByRoadUpdate,
  ByMileStone,
}

const assets_location = 'assets/images/svg/side-nav/';
const uds_icons = 'assets/uds_icons/';

export const SVGIcons = {
  dashboard: uds_icons + 'dashboard-3.svg',
  container: uds_icons + 'container-1.svg',
  truck: uds_icons + 'road-1.svg',
  road: 'assets/svg/truck_shipment2.svg',
  container_delivery: assets_location + 'dashboard/ContainerDelivery-2.svg',
  business: assets_location + 'dashboard/business-stats.svg',
  insights: assets_location + 'dashboard/insights&reports.svg',
  calendar: assets_location + 'dashboard/calendar.svg',
  quotations: assets_location + 'explorer/quotation.svg',
  shipments: 'assets/uds_icons/oceanSideNav.svg',
  containers: assets_location + 'explorer/containers.svg',
  container_upload: assets_location + 'polished/container-upload.svg',
  products: assets_location + 'products.svg',
  product2: 'assets/images/svg/Shipment/product.svg',
  shipment_detail: 'assets/images/svg/Shipment/shipment_detail.svg',
  locations: assets_location + 'edit_location.svg',
  social: assets_location + 'social.svg',
  ship: 'assets/images/svg/ship.svg',
  truck2: 'assets/svg/truck_shipment2.svg',
  rail: 'assets/uds_icons/rail.svg',
  multipleMode: 'assets/uds_icons/multipleMode.svg',
  setting: assets_location + 'configure.svg',
  tbg: 'assets/svg/tbg.svg',
  documents: 'assets/svg/documents.svg',
  air_freight: 'assets/uds_icons/airFreight.svg',
  air_shipments: 'assets/uds_icons/air-shipment-2.svg',
  air_shipment_gray: 'assets/uds_icons/air-shipments-gray.svg',
  ol_shipment_gray: 'assets/uds_icons/ol_shipment_gray.svg',
  outsourced_logistics: 'assets/uds_icons/Outsourced_logistic_icon.svg',
  orders: 'assets/uds_icons/order-2.svg',
  integrated_orders: 'assets/uds_icons/order-2.svg',
  container_gray: 'assets/uds_icons/addContainerGray.svg',
  schedules: 'assets/uds_icons/schedulesParent.svg',
  flight: `assets/uds_icons/flight.svg`,
  vessel: `assets/uds_icons/vessel.svg`,
  shipment: `assets/uds_icons/shipment-parent.svg`,
  calendar_nav: `assets/uds_icons/calendar-nav.svg`,
  analytics_nav: `assets/uds_icons/analytics-nav.svg`,
};

let AusTenantNavItemsList: SideNavBarItem[] = [
  {
    hasChild: false,
    navItem: new NavItem('/dashboard', SideNavLabel.DASHBOARD, SVGIcons.dashboard, null, true),
  },
  {
    hasChild: false,
    navItem: new NavItem('/purchase-orders', SideNavLabel.INTEGRATED_ORDERS, SVGIcons.integrated_orders, null, true, null),
  },
  {
    hasChild: true,
    navItem: new NavItem('/shipments', SideNavLabel.SHIPMENT, SVGIcons.shipment, null, true, 'shipments'),
    children: [
      {
        hasChild: false,
        navItem: new NavItem('/ocean-shipments/tracking-by-group', SideNavLabel.OCEAN_SHIPMENT, SVGIcons.shipments, null, true, null),
        isShown: false,
      },
      {
        hasChild: false,
        navItem: new NavItem('/air-shipments/tracking', SideNavLabel.AIR_SHIPMENT, SVGIcons.air_shipments, null, true, null),
        isShown: false,
      },
    ],
  },
  {
    hasChild: false,
    navItem: new NavItem('/calendar/ocean-shipments', SideNavLabel.CALENDAR, SVGIcons.calendar_nav, null, true, null),
  },
  {
    hasChild: false,
    navItem: new NavItem('/performance/ocean-shipments', SideNavLabel.ANALYTICS, SVGIcons.analytics_nav, null, true, null),
  },
];

let NavItemsList: SideNavBarItem[] = [
  {
    hasChild: false,
    navItem: new NavItem('/welcome', SideNavLabel.WELCOME, SVGIcons.schedules, null, true, null),
  },
  {
    hasChild: false,
    navItem: new NavItem('/dashboard', SideNavLabel.DASHBOARD, SVGIcons.dashboard, null, true, null),
  },
  {
    hasChild: true,
    navItem: new NavItem('/shipments', SideNavLabel.SHIPMENT, SVGIcons.shipment, null, true, 'shipments'),
    children: [
      // {
      //   hasChild: false,
      //   navItem: new NavItem('/container-shipments/tracking', SideNavLabel.OCEAN_SHIPMENT, SVGIcons.shipments, null, true, null),
      //   isShown: false
      // },
      {
        hasChild: false,
        navItem: new NavItem(ROUTE_PATH.OCEAN_TRACKING, SideNavLabel.OCEAN_SHIPMENT, SVGIcons.shipments, null, true, null),
        isShown: false,
      },
      {
        hasChild: false,
        navItem: new NavItem('/road-shipments/tracking', SideNavLabel.ROAD_SHIPMENT, SVGIcons.truck, null, true, null),
        isShown: false,
      },
      {
        hasChild: false,
        navItem: new NavItem('/air-shipments/tracking', SideNavLabel.AIR_SHIPMENT, SVGIcons.air_shipments, null, true, null),
        isShown: false,
      },
      {
        hasChild: false,
        navItem: new NavItem(
          '/outsourced-logistics/tracking',
          SideNavLabel.OUTSOURCED_LOGISTICS,
          SVGIcons.outsourced_logistics,
          null,
          true,
          null
        ),
        isShown: false,
      },
    ],
  },
  {
    hasChild: false,
    navItem: new NavItem('/calendar/ocean-shipments', SideNavLabel.CALENDAR, SVGIcons.calendar_nav, null, true, null),
  },
  {
    hasChild: false,
    navItem: new NavItem('/performance/ocean-shipments', SideNavLabel.ANALYTICS, SVGIcons.analytics_nav, null, true, null),
  },
  {
    hasChild: false,
    navItem: new NavItem('/orders', SideNavLabel.ORDERS, SVGIcons.orders, null, true, null),
  },
  {
    hasChild: true,
    navItem: new NavItem(ROUTE_PATH.VESSEL_SCHEDULE, SideNavLabel.SCHEDULES, SVGIcons.schedules, null, true, 'schedules'),
    children: [
      {
        hasChild: false,
        navItem: new NavItem(ROUTE_PATH.VESSEL_SCHEDULE, SideNavLabel.VESSEL_SCHEDULES, SVGIcons.vessel, null, true, null),
        isShown: false,
      },
      {
        hasChild: false,
        navItem: new NavItem(ROUTE_PATH.FLIGHT_SCHEDULE, SideNavLabel.FLIGHT_SCHEDULES, SVGIcons.flight, null, true, null),
        isShown: false,
      },
    ],
  },
  {
    hasChild: false,
    navItem: new NavItem('/container-pickup', SideNavLabel.INBOUND_CONTAINER_PICKUP, SVGIcons.container_delivery, null, true, null),
  },
];

// TODOD: Keep it for backup
/*let NavItemsList1: NavItem[] = [
  //new NavItem('/dashboard', 'Dashboard', SVGIcons.dashboard, null, true),
  // new NavItem('/dashboard', 'Dashboard', null, 'dashboard', true),
  new NavItem('/dashboard', SideNavLabel.DASHBOARD, SVGIcons.dashboard, null, true, null),
  new NavItem('/container-shipments', SideNavLabel.OCEAN_SHIPMENT, SVGIcons.shipments, null, true, null),
  new NavItem('/road-shipments', SideNavLabel.ROAD_SHIPMENT, SVGIcons.truck, null, true, null),
  new NavItem('/air-shipments/tracking', SideNavLabel.AIR_SHIPMENT, SVGIcons.air_shipments, null, true, null),
  new NavItem('/orders', SideNavLabel.ORDERS, SVGIcons.orders, null, true, null),
  new NavItem('/purchase-orders', SideNavLabel.INTEGRATED_ORDERS, SVGIcons.integrated_orders, null, true, null),
  //new NavItem('/tracking-by-group', 'Tracking by Group', null, 'view_list', !environment.productionHosting),
  // new NavItem('/containers', 'Containers', SVGIcons.containers, null, isProdOrStaging),
  // new NavItem('/shipments', 'Shipments', SVGIcons.shipments, null, isProdOrStaging),
  //new NavItem('/road-shipments', 'Road Shipments', SVGIcons.truck, null, true),
  //new NavItem('/tracking-by-group', 'Tracking by Group', SVGIcons.tbg, null, !environment.productionHosting),
  // new NavItem('/users', 'Users', null, 'person_outline', isProdOrStaging),
  // new NavItem('/setting', 'Setting', SVGIcons.setting, null, isProdOrStaging),
  // new NavItem('/contact-us', 'Contact US', null, 'perm_phone_msg', isProdOrStaging),
  new NavItem(null, SideNavLabel.SCHEDULES, SVGIcons.schedules, null, true, 'schedules'),
  new NavItem('/container-pickup', SideNavLabel.INBOUND_CONTAINER_PICKUP, SVGIcons.container_delivery, null, true, null),
  // new NavItem('/container-pickup2', 'Inbound Container Pickup', SVGIcons.container_delivery, null, !environment.productionHosting),
  // null,
  // new NavItem('/purchase-orders', 'Purchase Orders*', null, 'shopping_basket'),
  // new NavItem('/customer-orders', 'Customer Orders*', null, 'accessibility_outline'),
  // new NavItem('/shippingRequests', 'Quotations', SVGIcons.quotations),
  // new NavItem('/containers-table', 'Containers-table', SVGIcons.containers),
  // new NavItem('/shipments-old', 'Shipments', SVGIcons.shipments),
  // new NavItem('/products', 'Products', SVGIcons.products),
  // new NavItem('/relationship', 'Relationship*', null, 'wc'),
];*/

if (isProdOrStaging) {
  AusTenantNavItemsList = AusTenantNavItemsList.filter((item) => item.navItem && item.navItem.showInProd);
  NavItemsList = NavItemsList.filter((item) => item.navItem && item.navItem.showInProd);
}

export { NavItemsList };
export { AusTenantNavItemsList };

export enum TemplateFileExtensionTypes {
  csv,
  xlsx,
}
