import { Component, OnInit, Input } from '@angular/core';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';

@Component({
  selector: 'dp-shipment-segment',
  templateUrl: './shipment-segment.component.html',
  styleUrls: ['./shipment-segment.component.scss'],
})
export class ShipmentSegmentComponent implements OnInit {
  @Input() heading: string;
  @Input() segmentData: any;
  @Input() isIntermodalRoad: boolean = false;
  @Input() isRoad: boolean = false;
  @Input() isManualIntermodalLinkage: boolean = false;
  segmentDataTable = null;
  @Input() context: any;
  SvgMap = SvgMap;

  ngOnInit(): void {
    if (Array.isArray(this.segmentData)) {
      // build segmentTable
      if (this.heading === 'Shipping Segments' && this.isManualIntermodalLinkage) {
        this.heading = this.heading + ' - MIDDLE MILE';
      }
      this.segmentDataTable = this.buildShippingSegmentTable(this.segmentData);
    }
  }

  buildShippingSegmentTable(segmentData) {
    if (segmentData.length) {
      return this.isRoad || this.isIntermodalRoad ? {
        header: ['From', 'To', 'Vehicle | Phone Number','Departure', 'Arrival'],
        rows: this.getRoadLineTableRows(segmentData),
      } : {
        header: ['From', 'To', 'Transport Name', 'Trip #', 'Departure', 'Arrival'],
        rows: this.getOceanLineTableRows(segmentData),
      }
    }
    return null;
  }

  getRoadLineTableRows(segmentData: any[]) {
    return segmentData.map((data) => {
      return [
        {
          data: data.pickup,
          className: 'first-row',
        },
        {
          data: data.dropoff,
          className: '',
        },
        {
          data: this.combineTwoProperties(data.truckNumber, data.driverPhone, '| '),
          className: '',
        },
        {
          data: data.atd || data.etd,
          className: 'md',
          time: data.atd ? 'Actl.' : (data.etd ? 'Estd.' : ''),
        },
        {
          data: data.ata || data.eta,
          className: 'md',
          time: data.ata ? 'Actl.' : (data.eta ? 'Estd.' : ''),
        },
      ];
    });
  }
  getOceanLineTableRows(segmentData: any[]) {
    return segmentData.map((data) => {
      return [
        {
          data: data.origin,
          className: 'first-row',
        },
        {
          data: data.destination,
          className: '',
        },
        {
          data: data.transportName,
          className: '',
        },
        {
          data: data.tripNumber,
          className: '',
        },
        {
          data: data.atd || data.etd,
          className: 'md',
          time: data.atd ? 'Actl.' : (data.etd ? 'Estd.' : ''),
        },
        {
          data: data.ata || data.eta,
          className: 'md',
          time: data.ata ? 'Actl.' : (data.eta ? 'Estd.' : ''),
        },
      ];
    });
  }

  combineTwoProperties(p1: string, p2: string, separator = ' '): string {
    return p1 ? p1 + (p2 ?  separator + p2 : '') : '';
  }

  getPredictiveDaysDifference(hubEtaDaysDifference: number): string {
    if (!hubEtaDaysDifference) {
      return '';
    }
    let date = '';
    if (hubEtaDaysDifference > 0) {
      date = `(+${hubEtaDaysDifference}`;
    } else {
      date = `(${hubEtaDaysDifference}`;
    }
    if ([-1, 1].includes(hubEtaDaysDifference)) {
      return `${date} day)`
    }
    return `${date} days)`;
  }

  getPredictedTitle() {
    return this.context?.hubAta ? 'Flow Predicted' : 'Flow Predicts';
  }

  showPredictedEta(col, row) {
    return this.segmentDataTable?.header[col] === 'Arrival'
      && this.segmentDataTable?.rows[row][1].data === this.context?.destinationPort
      && this.context?.predictedEta;
  }
}