import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { IChatMessage, IChatbotResponse } from './chatbot.model';
import { finalize } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { ChatbotService } from './chatbot.service';

@Component({
  selector: 'dp-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  animations: [
    trigger('onOff', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class ChatbotComponent implements AfterViewInit {
  ctoText = 'CARGOES Flow GPT';
  chatInput = '';
  showSampleQueries = true;
  waitingResponse = false;
  chatMessages: IChatMessage[] = [];
  @ViewChild('chatInputElement') chatInputElementRef: ElementRef;

  sampleQueries = [
    // Todo Update UI question what is the vessel
    { q: 'What is the ETA of the container XYZ?', value: 'What is the ETA of the container ' },
    { q: 'Track container XYZ', value: 'Track container ' },
    { q: 'What is the CO<sub>2</sub> emission for XYZ container journey?', value: 'What is the CO2 emission for XYZ container journey?' },
  ];

  constructor(public chatbotService: ChatbotService) {}

  toggleChat() {
    this.chatbotService.toggleChatbot();
  }

  selectQuery(q: string) {
    this.chatInput = q;
    this.setFocusOnChatInput();
  }

  setFocusOnChatInput() {
    setTimeout(() => {
      this.chatInputElementRef.nativeElement.focus();
    }, 300);
  }

  addToChatMessages(content: string, sender: string) {
    this.chatMessages.push({ content, user: sender });
  }

  prepareQuery(query: string): string {
    return query ? query.replace(/\n/g, '').trim() : '';
  }
  
  onSubmit(query: string) {
    this.showSampleQueries = false;
    this.chatInput = '';
    this.addToChatMessages(query, this.chatbotService.userInitial);
    this.sendToChatbotApi(query);

    setTimeout(() => {
      const bottomSection = document.getElementById('bottomSection');
      bottomSection.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 0);
  }

  onKeyEnter(query: string) {
    const sanitizedQuery = this.prepareQuery(query);
    if(sanitizedQuery){
      this.onSubmit(sanitizedQuery);
    }
  }

  sendToChatbotApi(query) {
    this.waitingResponse = true;

    this.chatbotService
      .getChatResponse(query)
      .pipe(
        finalize(() => {
          this.waitingResponse = false;
          this.setFocusOnChatInput();
          setTimeout(() => {
            const bottomSection = document.getElementById('bottomSection');
            bottomSection.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }, 0);
        })
      )
      .subscribe(
        (res: IChatbotResponse) => {
          if (res?.chatbotResponse) this.addToChatMessages(res.chatbotResponse, 'chatbot');
          else {
            this.addToChatMessages(
              'I am sorry, but I did not understand the input you provided. You can reach out to support@cargoesflow.com for your query.',
              'chatbot'
            );
          }
        },
        (error) => {
          console.log(error);
          if (error?.error?.chatbotResponse) {
            this.addToChatMessages(error.error.chatbotResponse, 'chatbot');
          } else {
            this.addToChatMessages(
              "I'm sorry, but I did not understand the input you provided. You can reach out to support@cargoesflow.com for your query.",
              'chatbot'
            );
          }
        }
      );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.ctoText = null;
    }, 4000);
  }
}
