import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatTreeModule } from '@angular/material/tree';
import { DpProgressBarComponent } from './progress-bar/progress-bar.component';
import { DpFilterLabelComponent } from './filter-label/filter-label.component';
import { DpPipesModule } from '@dp/pipes/pipes.module';
import { ShipmentTimelineComponent } from 'app/shipments2/shipment-timeline/shipment-timeline.component';
import { MdePopoverModule } from '@material-extended/mde';
import { CheckboxTreeComponent } from './checkbox-tree/checkbox-tree.component';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { PortComponent } from './port/port.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DpDirectivesModule } from '@dp/directives/directives.module';
import { ShipmentTimelineHorizontalComponent } from 'app/shipments2/shipment-timeline-horizontal/shipment-timeline-horizontal.component';
import { FlaggedTimeComponent } from './flagged-time/flagged-time.component';
import { AddressComponent } from './address/address.component';
import { MaterialModule } from 'app/material.module';
// import { NewSettingsComponent } from 'app/settings/new-settings/new-settings.component';
import { UserProfileComponent } from 'app/settings/user-profile/user-profile.component';
import { DescriptionListComponent } from './description-list/description-list.component';
// import { CompanySettingComponent } from 'app/settings/organization/company-setting/company-setting.component';
import { DpMatTable } from '@dp/components/mat-table/mat-table.component';
import { DpSearchBoxComponent } from './search-box/search-box.component';
import { LogoComponent } from './logo/logo.component';
import { TagsComponent } from './tags/tags.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NotificationSliderComponent } from '@dp/components/notification-slider/notification-slider.component';
import { NotificationSettingComponent } from 'app/navigation/header/notification/notification-setting/notification-setting.component';
import { ScheduleDetailComponent } from '@dp/components/schedule-detail/schedule-detail.component';
import { ScheduleDetailListComponent } from '@dp/components/schedule-detail/schedule-detail-list/schedule-detail-list.component';
import { MiniFabComponent } from './mini-fab/mini-fab.component';
import { SelectAutocompleteModule } from './select-autocomplete/select-autocomplete.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SvgComponent } from 'app/shared/components/svg/svg.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
// import { UserNotificationComponent } from 'app/settings/user-notification/user-notification.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { PortSelectComponent } from './port-select/port-select.component';
import { VesselSelectComponent } from './vessel-select/vessel-select.component';
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';
import { ActlEstdComponent } from './actl-estd/actl-estd.component';
import { DataPointComponent } from './temperature-humidity/data-point/data-point.component';
import { DataRangeComponent } from '../shared/components/temperature-humidity/data-range/data-range.component';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NoResultComponent } from './no-result/no-result.component';
const MyDefaultTooltipOptions: TooltipOptions = {
  'hide-delay': 0,
};
@NgModule({
  declarations: [
    DpProgressBarComponent,
    DpFilterLabelComponent,
    ShipmentTimelineComponent,
    ShipmentTimelineHorizontalComponent,
    CheckboxTreeComponent,
    PortComponent,
    FlaggedTimeComponent,
    AddressComponent,
    NotificationSliderComponent,
    NotificationSettingComponent,
    UserProfileComponent,
    // CompanySettingComponent,
    DescriptionListComponent,
    DpMatTable,
    DpSearchBoxComponent,
    LogoComponent,
    TagsComponent,
    ScheduleDetailComponent,
    ScheduleDetailListComponent,
    MiniFabComponent,
    SvgComponent,
    SvgIconComponent,
    PortSelectComponent,
    VesselSelectComponent,
    CustomSnackbarComponent,
    ActlEstdComponent,
    DataPointComponent,
    DataRangeComponent,
    DropdownComponent,
    NoResultComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDividerModule,
    FontAwesomeModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatTreeModule,
    MatCheckboxModule,
    MatTooltipModule,
    MdePopoverModule,
    MatTableModule,
    MatToolbarModule,
    FormsModule,
    MatInputModule,
    DpPipesModule,
    DpDirectivesModule,
    MaterialModule,
    MatAutocompleteModule,
    SelectAutocompleteModule,
    InlineSVGModule,
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
  ],
  exports: [
    CdkTableModule,
    CdkTreeModule,
    SelectAutocompleteModule,
    DpProgressBarComponent,
    DpFilterLabelComponent,
    ShipmentTimelineComponent,
    ShipmentTimelineHorizontalComponent,
    PortComponent,
    FlaggedTimeComponent,
    AddressComponent,
    UserProfileComponent,
    DescriptionListComponent,
    DpMatTable,
    DpSearchBoxComponent,
    LogoComponent,
    TagsComponent,
    ScheduleDetailComponent,
    ScheduleDetailListComponent,
    NotificationSliderComponent,
    MiniFabComponent,
    NotificationSettingComponent,
    SvgComponent,
    SvgIconComponent,
    PortSelectComponent,
    VesselSelectComponent,
    CustomSnackbarComponent,
    ActlEstdComponent,
    DataPointComponent,
    DataRangeComponent,
    TooltipModule,
    DropdownComponent,
    NoResultComponent,
  ],
})
export class DpComponentsModule {}
