<span type="button" class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</span>

<div mat-dialog-title>Customised New Configuration</div>

<form [formGroup]="formGroup" class="px-8 py-16 mb-4  easy-form">
  <!-- add mode -->

  <section class="d-flex justify-content-between align-items-center">
    <dp-port-select label="Search for a port" [options]="ports" style="width: 430px"
      (optionChanged)="onOptionChanged($event)"></dp-port-select>
  </section>

  <section class="d-flex justify-content-between align-items-center">
    <mat-form-field appearance="outline" style="width: 430px">
      <mat-label color="accent">Search for a shipping line</mat-label>
      <mat-select formControlName="carrier" placeholder="Carrier" #carrierSelect [disableOptionCentering]="true" panelClass="no-change">
        <mat-option>
          <ngx-mat-select-search [formControl]="carrierFilterCtrl" placeholderLabel="Search..."
            noEntriesFoundLabel="'no matching carrier found'">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier">{{ carrier }}</mat-option>
      </mat-select>
      <button mat-button matSuffix aria-label="Clear" (click)="deleteCarrier($event)" class="deleteCarrier">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </section>

  <section *ngIf="formGroup.errors">
    <mat-error *ngIf="formGroup.dirty && formGroup.errors['invalidRange']">
      Medium severity value must be less than High severity value.
    </mat-error>
  </section>
  <section *ngIf="mediumSeverityToleranceSecsCtrl.errors">
    <mat-error *ngIf="mediumSeverityToleranceSecsCtrl.errors['min'] || mediumSeverityToleranceSecsCtrl.errors['max']">
      Medium severity value is invalid.
    </mat-error>
  </section>
  <section *ngIf="highSeverityToleranceSecsCtrl.errors">
    <mat-error *ngIf="highSeverityToleranceSecsCtrl.errors['min'] || highSeverityToleranceSecsCtrl.errors['max']">
      High severity value is invalid.
    </mat-error>
  </section>
  <!-- <section *ngIf="demurrageFreeDaysCtrl?.errors">
    <mat-error *ngIf="demurrageFreeDaysCtrl.errors['min']">
      Demurrage value is invalid.
    </mat-error>
  </section>
  <section *ngIf="detentionFreeDaysCtrl?.errors">
    <mat-error *ngIf="detentionFreeDaysCtrl.errors['min']">
      Detention value is invalid.
    </mat-error>
  </section> -->

  <div fxLayout="row" fxLayoutAlign="space-between start">
    <div class="withNumInput w-160">
      Medium severity
    </div>
    <div class="withNumInput">
      Storage time more than
    </div>
    <mat-form-field appearance="outline" class="w-88">
      <mat-label>Days</mat-label>
      <input matInput type="number" min="1" max="60" formControlName="mediumSeverityToleranceSecs">
    </mat-form-field>

  </div>

  <div fxLayout="row" fxLayoutAlign="space-between start">
    <div class="withNumInput  w-160">
      High severity
    </div>
    <div class="withNumInput">
      Storage time more than
    </div>
    <mat-form-field appearance="outline" class="w-88">
      <mat-label>Days</mat-label>
      <input matInput type="number" min="1" max="60" formControlName="highSeverityToleranceSecs">
    </mat-form-field>
  </div>

  <!-- Show only when isDemurrageDetentionEnabled-->
  <ng-container *ngIf="data.isDemurrageDetentionEnabled">
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div class="withNumInput">
        Demurrage Free Days
      </div>
      <mat-form-field appearance="outline" class="w-88">
        <mat-label>Days</mat-label>
        <input matInput type="number" min="1" formControlName="demurrageFreeDays">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div class="withNumInput">
        Detention Free Days
      </div>
      <mat-form-field appearance="outline" class="w-88">
        <mat-label>Days</mat-label>
        <input matInput type="number" min="1" formControlName="detentionFreeDays">
      </mat-form-field>
    </div>
  </ng-container>

  <div>
    <button mat-flat-button class="easy-button secondary mr-12" (click)="dispatchAction('CLEAR')">
      Cancel
    </button>
    <button mat-flat-button class="easy-button uds-configration-button" (click)="dispatchAction('CREATE')"
      [disabled]="!isFormValid()">CREATE CONFIGURATION</button>
  </div>

</form>