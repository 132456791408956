import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Utility } from '@dp/utilities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from 'app/auth/auth.service';
import { ACCOUNT_TYPES } from 'app/auth/user.model';
import { SharedApiService } from 'app/shared/shared.service';
import { environment } from 'environments/environment';
import { SvgMap } from '../svg/uds-svg-map';
import { RouteVsPageIdentifier } from './feedback-widget.constant';
import { FeedbackMetadata } from './feedback-widget.model';

@UntilDestroy()
@Component({
  selector: 'dp-feedback-widget',
  templateUrl: './feedback-widget.component.html',
  styleUrls: ['./feedback-widget.component.scss'],
})
export class FeedbackWidgetComponent implements OnInit, OnChanges {
  SvgMap = SvgMap;
  @Input() isLoggedIn: boolean;
  showFeedbackWidget = false;
  feedbackMetadataList;
  currentPageFeedbackMetadata: FeedbackMetadata;
  currentPageIdentifier;
  environmentType: string;
  closeWidgetMap = Utility.getItemDecrypted(environment.storage.feedbackWidgetSession, 'session') || {};
  isWidgetAppeared = false;
  isUserPlus = false;

  constructor(public authService: AuthService, private sharedApiService: SharedApiService, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isWidgetAppeared = false;
        const urlTokens = event.urlAfterRedirects.split('/');
        const route = urlTokens[1].split('?')[0];
        this.currentPageIdentifier = RouteVsPageIdentifier[`/${route}`];
        this.currentPageIdentifier?.onPageLoad && this.routeFeedbackMetadataHandler();
      }
    });
  }

  ngOnInit(): void {
    this.environmentType = environment.environmentType;
    this.sharedApiService.initFeedbackWidget$.pipe(untilDestroyed(this)).subscribe((bool) => {
      if (bool) {
        this.routeFeedbackMetadataHandler();
      }
    });
    this.authService.currentUser.subscribe((user) => {
      this.isUserPlus = user?.accountType === ACCOUNT_TYPES.PLUS;
    });
  }

  ngOnChanges(changes) {
    !this.isUserPlus && this.sharedApiService.getFeedbackMetadata(changes.isLoggedIn.currentValue).subscribe((res) => {
      this.feedbackMetadataList = res['feedbackWidgetMetadata'];
      this.currentPageIdentifier?.onPageLoad && !this.currentPageFeedbackMetadata && this.routeFeedbackMetadataHandler();
    });
  }

  toggleFeedback() {
    this.showFeedbackWidget = !this.showFeedbackWidget;
  }

  routeFeedbackMetadataHandler() {
    this.currentPageFeedbackMetadata =
      this.feedbackMetadataList &&
      this.feedbackMetadataList.find((item) => item.applicationPageIdentifier === this.currentPageIdentifier?.title);
  }

  onCrossClick() {
    this.pushEventToDatalayer(environment.gaEvents.actions.feedbackWidgetTrigger, environment.gaEvents.actions.crossButtonClick);
    this.onClose();
  }

  onClose() {
    this.closeWidgetMap[this.currentPageIdentifier.title] = true;
    Utility.setItemEncrypted(environment.storage.feedbackWidgetSession, this.closeWidgetMap, 'session');
  }

  onSuccessCallback() {
    this.pushEventToDatalayer(environment.gaEvents.actions.feedbackWidgetDialog, environment.gaEvents.actions.fwThankYouDoneClick);
    this.onClose();
  }

  showWidget() {
    if (
      !this.isUserPlus &&
      this.currentPageFeedbackMetadata &&
      this.currentPageIdentifier &&
      this.currentPageFeedbackMetadata.applicationPageIdentifier === this.currentPageIdentifier.title &&
      !this.closeWidgetMap[this.currentPageIdentifier?.title]
    ) {
      if (!this.isWidgetAppeared) {
        this.isWidgetAppeared = true;
        this.pushEventToDatalayer(environment.gaEvents.actions.feedbackWidgetTrigger, environment.gaEvents.actions.feedbackWidgetDisplayed);
      }
      return true;
    }
    return false;
  }

  onRateUs() {
    this.pushEventToDatalayer(environment.gaEvents.actions.feedbackWidgetTrigger, environment.gaEvents.actions.rateUsClick);
    this.toggleFeedback();
  }

  pushEventToDatalayer(event: string, eventAction: string) {
    window['dataLayer'].push({
      eventDetail: null,
    });
    window['dataLayer'].push({
      event,
      eventDetail: {
        eventCategory: environment.gaEvents.categories.feedbackWidget,
        eventAction,
        eventLabel: this.currentPageFeedbackMetadata?.feedbackWidgetName,
      },
    });
  }
}
