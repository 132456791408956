import { Component, OnInit, Input } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { dpAnimations } from '@dp/animations';
import { EntityType } from '@dp/types';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { ShipmentMap } from 'app/shipments2/shipments2.model';
import { AuthService } from '../../../auth/auth.service';

// for portToPort
@Component({
  selector: 'dp-tracking-result',
  templateUrl: './_tracking-result.component.html',
  styleUrls: ['./_tracking-result.component.scss'],
  animations: dpAnimations,
})
export class TrackingResultComponent implements OnInit {
  SvgMap = SvgMap;
  EntityType = EntityType;
  showReportError = false;
  @Input() payload;
  @Input() isBusy = false;
  @Input() errorText = '';
  @Input() tabs: string[] = ['Tracking'];
  @Input() tokenId: string;
  @Input() showDocumentsInSharedLinks: boolean;
  shipmentMap: ShipmentMap;
  transportJourneys: any;
  segmentDataTable: any;
  isUserLoggedIn = false;
  exceptionCount = 0;
  @Input() showException = true;
  private isPredictiveEtaEnabled: boolean;

  constructor(private authService: AuthService) {
    const user = authService.currentUserValue;
    if (user) {
      this.isUserLoggedIn = true;
      this.isPredictiveEtaEnabled = user?.isPredictiveEtaEnabled;
    }
  }

  ngOnInit(): void {
    const payload = this.payload;

    if (payload) {
      if (payload.mapsData) {
        this.shipmentMap = payload.mapsData;
      }
      if (payload.transportJourneys) {
        this.transportJourneys = payload.transportJourneys;
      }

      if (Array.isArray(payload.transportJourneys?.portToPort?.segmentData)) {
        // build segmentTable
        this.segmentDataTable = this.buildShippingSegmentTable(payload.transportJourneys.portToPort.segmentData);
      }

      if (payload.error) {
        console.warn(payload.error);
        this.errorText = payload.error;
      }

      if (payload.exceptions?.steps) {
        payload.exceptions.steps.forEach((step) => {
          this.exceptionCount += step.exceptions.length;
        });
      }
    }
  }

  buildShippingSegmentTable(segmentData) {
    if (segmentData.length) {
      return {
        header: ['From', 'To', 'Transport Name', 'Trip #', 'Departure', 'Arrival'],
        rows: this.getOceanLineTableRows(segmentData),
      };
    }
    return null;
  }

  getOceanLineTableRows(segmentData: any[]) {
    return segmentData.map((data) => {
      return [
        {
          data: data.origin,
          className: '',
        },
        {
          data: data.destination,
          className: '',
        },
        {
          data: data.transportName,
          className: '',
        },
        {
          data: data.tripNumber,
          className: '',
        },
        {
          data: data.atd || data.etd,
          className: data.atd ? 'Actl.' : 'Estd.',
        },
        {
          data: data.ata || data.eta,
          className: data.ata ? 'Actl.' : 'Estd.',
        },
      ];
    });
  }

  reportError() {
    this.showReportError = true;
  }

  close() {
    this.showReportError = false;
  }

  selectedTabIndex = 0;
  selectedTabChange(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
  }

  getPredictedEtaContext() {
    const portToPort = this.transportJourneys?.portToPort;
    return {
      hubAta: portToPort.hubAta,
      predictedEta: portToPort.predictedHubEta,
      hubEtaDaysDifference: portToPort.hubEtaDaysDifference,
      destinationPort: portToPort.destinationPort,
    };
  }

  isEmpty(exception): boolean {
    return !exception || Object.keys(exception).length === 0;
  }
}
