import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'app/shared/components/confirm-dialog/confirm-dialog.component';
import { StaticDataService } from '@dp/services/static-data.service';
import { Utility } from '@dp/utilities';
import { AuthService } from 'app/auth/auth.service';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';

export interface IFormData {
  id?: number;
  port: string; // for UI
  carrier: string; // for UI
  countryCode: string; //for UI
  portCode: string; // use this to send to backend
  carrierScac: string;
  notificationCode: 'CN_EXTENDED_POO_STORAGE' | 'CN_EXTENDED_POD_STORAGE' | 'ARRIVING_SOON';
  defaultToleranceSecs: number;
  mediumSeverityToleranceSecs: number;
  highSeverityToleranceSecs: number;
  demurrageFreeDays?: number;
  detentionFreeDays?: number;
}

interface IOption {
  isAdmin: boolean;
  mode: 'CREATE' | 'UPDATE' | 'DELETE' | 'CLEAR' | 'CANCEL' | '';
  notificationCode: string;
  ports: any[];
}
@Component({
  selector: 'dp-notification-metric-form',
  templateUrl: './metric-form.component.html',
  styleUrls: ['./metric-form.component.scss'],
})
export class MetricFormComponent implements OnInit {
  SvgMap = SvgMap;
  @Input() currentMode: 'ADD_POO' | 'ADD_POD' | '';
  @Output() currentModeChange = new EventEmitter();
  @Input() editingIds: Set<number>;

  isEditing: boolean = false;
  isDemurrageDetentionEnabled: boolean;

  // option configuration
  private _options: IOption = {
    isAdmin: false,
    mode: '',
    notificationCode: '',
    ports: [],
  };

  @Input()
  get options() {
    return this._options;
  }
  set options(config: Partial<IOption>) {
    this._options = { ...this._options, ...config };
  }

  @Input() formData: IFormData;
  @Output() onDispatch = new EventEmitter();

  formGroup = this.fb.group(
    {
      id: [],
      port: [null],
      carrier: [null],
      countryCode: [null],
      portCode: [''],
      carrierScac: [null],
      notificationCode: ['', [Validators.required]],
      toleranceSecs: [null],
      mediumSeverityToleranceSecs: [null, [Validators.min(1), Validators.max(60)]],
      highSeverityToleranceSecs: [null, [Validators.min(1), Validators.max(60)]],
      demurrageFreeDays: [null, [Validators.min(1)]],
      detentionFreeDays: [null, [Validators.min(1)]],
    },
    { validators: this.checkMediumHighValue }
  );

  checkMediumHighValue(c: AbstractControl) {
    const medium = Number(c.get('mediumSeverityToleranceSecs').value);
    const high = Number(c.get('highSeverityToleranceSecs').value);
    if (!medium && !high) return null;
    if (medium && medium < high) return null;
    // error - medium >= high
    return {
      invalidRange: true,
    };
  }

  get mediumSeverityToleranceInDays() {
    const days = this.formGroup.get('mediumSeverityToleranceSecs').value;
    return days;
  }

  get highSeverityToleranceInDays() {
    return this.formGroup.get('highSeverityToleranceSecs').value;
  }

  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private staticDataService: StaticDataService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.formData) {
      let transform = {
        ...this.formData,
        mediumSeverityToleranceSecs: Utility.secondsToDays(this.formData.mediumSeverityToleranceSecs),
        highSeverityToleranceSecs: Utility.secondsToDays(this.formData.highSeverityToleranceSecs),
        demurrageFreeDays: Utility.secondsToDays(this.formData.demurrageFreeDays),
        detentionFreeDays: Utility.secondsToDays(this.formData.detentionFreeDays),
      };
      this.formGroup.patchValue(transform);
    }

    this.formGroup.valueChanges.subscribe((value) => {
      this.isEditing = true;
    });
    this.authService.currentUser.subscribe((user) => {
      this.isDemurrageDetentionEnabled = user?.accountCapabilities?.isDemurrageDetentionEnabled;
    });
  }

  cancelEditing() {
    if (this.formData) {
      let transform = {
        ...this.formData,
        mediumSeverityToleranceSecs: Utility.secondsToDays(this.formData.mediumSeverityToleranceSecs),
        highSeverityToleranceSecs: Utility.secondsToDays(this.formData.highSeverityToleranceSecs),
        demurrageFreeDays: Utility.secondsToDays(this.formData.demurrageFreeDays),
        detentionFreeDays: Utility.secondsToDays(this.formData.detentionFreeDays),
      };
      this.formGroup.patchValue(transform, {
        emitEvent: false,
      });
    }
    this.isEditing = false;
  }

  confirmDelete() {
    const message = `Are you sure you want to delete this port customization?`;
    const dialogData = new ConfirmDialogModel('Delete a port customization', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '450px',
      data: dialogData,
      panelClass: 'app-confirm-dialog'
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      //true: delete
      if (dialogResult) {
        this.dispatchAction('DELETE');
      }
    });
  }

  dispatchAction(action: string) {
    let emitObject = {
      options: { ...this.options, mode: action },
      data: this.formGroup.value,
    };
    this.onDispatch.emit(emitObject);
    // optimistic assumption that API succeeds
    if (action === 'UPDATE') {
      this.isEditing = false;
      this.formData = {
        ...this.formData,
        mediumSeverityToleranceSecs: Utility.daysToSeconds(this.formGroup.value.mediumSeverityToleranceSecs),
        highSeverityToleranceSecs: Utility.daysToSeconds(this.formGroup.value.highSeverityToleranceSecs),
        demurrageFreeDays: Utility.daysToSeconds(this.formGroup.value.demurrageFreeDays),
        detentionFreeDays: Utility.daysToSeconds(this.formGroup.value.detentionFreeDays),
      };
    }
  }

  getPortName(form: IFormData) {
    let portName = '';
    if (form.port) {
      portName += form.port;
    }
    if (form.countryCode) {
      portName += ', ' + form.countryCode;
    }
    if (portName) return portName;
    if (form.portCode) return form.portCode;
    return 'Default for all ports';
  }
  getCarrierName(carrierScac: string) {
    const staticData = this.staticDataService.getStaticDataDirect();
    const name = staticData['carriers'].filter((carrier) => carrier.carrier_scac === carrierScac).map((carrier) => carrier.carrier_name);
    const carrierName = carrierScac === null ? '' : name.length > 0 ? name[0] : '';
    return carrierName;
  }
  // For CN_EXTENDED_POO_STORAGE and CN_EXTENDED_POD_STORAGE code
  isFormValid() {
    return this.formGroup.valid && !this.isFormEmpty();
  }

  isFormEmpty() {
    const formValue = this.formGroup.value;
    if (formValue) {
      if (
        formValue.demurrageFreeDays ||
        formValue.detentionFreeDays ||
        formValue.highSeverityToleranceSecs ||
        formValue.mediumSeverityToleranceSecs
      )
        return false;
    }
    return true;
  }

  isDefaultMetric(formGroup): boolean {
    return !formGroup.value.id || !formGroup.value.portCode;
  }

  get mediumSeverityToleranceSecsCtrl() {
    return this.formGroup.get('mediumSeverityToleranceSecs');
  }

  get highSeverityToleranceSecsCtrl() {
    return this.formGroup.get('highSeverityToleranceSecs');
  }
}
