import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dp-air-shipment-segment',
  templateUrl: './air-shipment-segment.component.html',
  styleUrls: ['./air-shipment-segment.component.scss'],
})
export class AirShipmentSegmentComponent implements OnInit {
  @Input() heading: string;
  @Input() segmentData: any;
  segmentDataTable = null;

  ngOnInit(): void {
    console.log('segment data: ', this.segmentData);

    if (Array.isArray(this.segmentData)) {
      // build segmentTable
      this.segmentDataTable = this.buildShippingSegmentTable(this.segmentData);
    }
  }

  buildShippingSegmentTable(segmentData) {
    if (segmentData.length) {
      return {
        header: ['Flight', 'Origin airport', 'Destination airport', 'Flight #', 'Departure', 'Arrival'],
        rows: this.getOceanLineTableRows(segmentData),
      };
    }
    return null;
  }

  getOceanLineTableRows(segmentData: any[]) {
    return segmentData.map((data) => {
      return [
        {
          data: data.carrier,
          className: 'first-row',
        },
        {
          data: data.origin,
          className: '',
        },
        {
          data: data.destination,
          className: '',
        },
        {
          data: data.tripNumber,
          className: '',
        },
        {
          data: data.atd || data.etd,
          className: 'md',
          time: data.atd ? 'Actl.' : data.etd ? 'Estd.' : '',
        },
        {
          data: data.ata || data.eta,
          className: 'md',
          time: data.ata ? 'Actl.' : data.eta ? 'Estd.' : '',
        },
      ];
    });
  }
}
