import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { AuthService } from '../auth.service';
import { UIService, ProgressService } from 'app/shared';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Component({
  selector: 'dp-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
  animations: dpAnimations,
})
export class ChangeEmailComponent implements OnInit {
  hasResult = false;
  config = { scrollXMarginOffset: 20 };
  error = '';
  verificationCode: string;
  @ViewChild('contentZone') contentZone?: ElementRef;

  constructor(
    private authService: AuthService,
    private uiService: UIService,
    private progressService: ProgressService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.verificationCode = params['verificationCode'];
      let progress = this.progressService.showProgress(this.contentZone);

      this.authService
        .changeEmailConfirm(this.verificationCode)
        .pipe(finalize(() => this.progressService.detach(progress)))
        .subscribe(
          (result) => {
            this.hasResult = true;
          },
          (error) => {
            this.error =
              error.error?.errorCode === '40810'
                ? 'This verification code is expired. Please resend another request!'
                : "We can't process this right now. Please try again later.";
          }
        );
    });
  }
}
