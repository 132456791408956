import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { VesselInfoComponent } from './components/container-tracking-info/vessel-info/vessel-info.component';
import { MaterialModule } from 'app/material.module';
import { ContainerTrackingInfoComponent } from './components/container-tracking-info/container-tracking-info.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DpComponentsModule } from '@dp/components/components.module';
import { ProductsTableComponent } from 'app/shared/components/products-table/products-table.component';
import { DpPipesModule } from '@dp/pipes/pipes.module';
import { CargoesFeaturesComponent } from './components/cargoes-features/cargoes-features.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DocumentPreviewDialogComponent } from './components/document-preview-dialog/document-preview-dialog.component';
import { DocumentUploadDialogComponent } from './components/document-upload-dialog/document-upload-dialog.component';
import { SharingLinkComponent } from './components/sharing-link/sharing-link.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from 'environments/environment';
import { ShipmentTrackingInfoComponent } from './components/shipment-tracking-info/shipment-tracking-info.component';
import { VesselInfo2Component } from './components/container-tracking-info/vessel-info2/vessel-info2.component';
import { DpDirectivesModule } from '@dp/directives/directives.module';
import { InlineSVGModule } from 'ng-inline-svg';

// tracking-info widget components
import { TrackingSummaryComponent } from './tracking-info-widget/tracking-summary/tracking-summary.component';
import { ShipmentMapComponent } from './tracking-info-widget/shipment-map/shipment-map.component';
import { TrackingStepComponent } from './tracking-info-widget/tracking-step/tracking-step.component';
import { TrackingDetailsComponent } from './tracking-info-widget/tracking-details/tracking-details.component';
import { ContainerDetailsComponent } from './tracking-info-widget/container-details/container-details.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TemperatureHumidityComponent } from './components/temperature-humidity/temperature-humidity.component';
import { TrackingResultComponent } from './tracking-info-widget/_tracking-result/_tracking-result.component';
import { TrackingResultRoadTransportComponent } from './tracking-info-widget/_tracking-result-roadTransport/_tracking-result-road-transport.component';
import { ShipmentSegmentComponent } from './tracking-info-widget/shipment-segment/shipment-segment.component';

import { DocumentsUploadCardComponentDatachain } from './components/document-upload-dialog/documents-upload-card-datachain/documents-upload-card-datachain.component';
import { DocumentsUploadCardComponent } from './components/documents-upload/documents-upload-card/documents-upload-card.component';
import { DocumentsUploadCardComponent2 } from './components/documents-upload/documents-upload-card-2/documents-upload-card-2.component';
import { DocumentsUploadCardPublicComponent } from './components/documents-upload/documents-upload-card-public/documents-upload-card-public.component';
import { TrackingResultMblBkComponent } from './tracking-info-widget/tracking-result-mbl-bk/tracking-result-mbl-bk.component';
import { ContainerAccordionComponent } from './tracking-info-widget/tracking-result-mbl-bk/container-accordion/container-accordion.component';
import { DocumentsUploadComponent } from './components/documents-upload/documents-upload.component';
import { ReportErrorComponent } from './components/report-error/report-error.component';
import { TrackingResultAirComponent } from './tracking-info-widget/_tracking-result-air/_tracking-result-air.component';
import { AirShipmentDetailsComponent } from './tracking-info-widget/_tracking-result-air/air-shipment-details/air-shipment-details.component';
import { AirShipmentSegmentComponent } from './tracking-info-widget/_tracking-result-air/air-shipment-segment/air-shipment-segment.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ShipmentProductDlg } from './components/products-table/shipment-product-dlg/shipment-product-dlg.component';
import { ProductSelectComponent } from './components/products-table/product-select/product-select.component';
import { OrderSelectComponent } from './components/products-table/order-select/order-select.component';
import { ShipmentNoteComponent } from './components/shipment-note/shipment-note.component';
import { AirShipmentDetails2Component } from './tracking-info-widget/_tracking-result-air/air-shipment-details-2/air-shipment-details-2.component';
import { MentionsModule } from './mentions';
import { DemurrageConfigurationComponent } from './components/demurrage-configuration/demurrage-configuration.component';
import { ShipmentNoteAddComponent } from './components/shipment-note-add/shipment-note-add.component';
import { SaveSearchDialogComponent } from './components/save-search-dialog/save-search-dialog.component';
import { SaveSearchBtnComponent } from './components/save-search-btn/save-search-btn.component';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { OceanShipmentsDetailComponent } from './components/ocean-shipments-details/ocean-shipments-detail.component';
import { MdePopoverModule } from '@material-extended/mde';
import { OsExceptionComponent } from './components/ocean-shipments-details/os-exception/os-exception.component';
import { ShipmentDetailsTabComponent } from './components/ocean-shipments-details/details-tab/shipment-details-tab.component';
import { OceanShipmentUpdateComponent } from './components/ocean-shipment-update/ocean-shipment-update.component';
import { SensorComponent } from './components/sensor/sensor.component';
import { CheckConsentStatusComponent } from './components/checkConsentStatus/checkConsentStatus.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { OLShipmentSegmentComponent } from './tracking-info-widget/_tracking-result-ol/ol-shipment-segment/ol-shipment-segment.component';
import { TrackingResultOLComponent } from './tracking-info-widget/_tracking-result-ol/_tracking-result-ol.component';
import { OLShipmentDetailsComponent } from './tracking-info-widget/_tracking-result-ol/ol-shipment-details/ol-shipment-details.component';
import { OLShipmentDetails2Component } from './tracking-info-widget/_tracking-result-ol/ol-shipment-details-2/ol-shipment-details-2.component';
import { MilestonesAddUpdateComponent } from './components/milestones-add-update/milestones-add-update.component';
import { ShipmentBingMapsComponent } from './tracking-info-widget/shipment-map/shipment-bing-maps/shipment-bing-maps.component';
import { ShipmentBingMapsDialogComponent } from './tracking-info-widget/shipment-map/shipment-bing-maps-dialog/shipment-bing-maps-dialog.component';
import { AuthService } from 'app/auth/auth.service';
import { RoadShipmentDetailsComponent } from './tracking-info-widget/_tracking-result-roadTransport/road-shipment-details/road-shipment-details.component';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
const MyDefaultTooltipOptions: TooltipOptions = {
  'hide-delay': 0,
};
@NgModule({
  declarations: [
    CargoesFeaturesComponent,
    ContainerTrackingInfoComponent,
    VesselInfoComponent,
    VesselInfo2Component,
    ProductsTableComponent,
    ConfirmDialogComponent,
    DocumentPreviewDialogComponent,
    DocumentsUploadComponent,
    DocumentUploadDialogComponent,
    CheckConsentStatusComponent,
    SharingLinkComponent,
    ShipmentTrackingInfoComponent,
    TrackingSummaryComponent,
    TrackingStepComponent,
    ShipmentMapComponent,
    ShipmentSegmentComponent,
    TemperatureHumidityComponent,
    TrackingDetailsComponent,
    ContainerDetailsComponent,
    DocumentsUploadCardComponentDatachain,
    DocumentsUploadCardComponent,
    DocumentsUploadCardComponent2,
    DocumentsUploadCardPublicComponent,
    TrackingResultMblBkComponent,
    ContainerAccordionComponent,
    ReportErrorComponent,
    TrackingResultComponent,
    TrackingResultRoadTransportComponent,
    TrackingResultAirComponent,
    TrackingResultOLComponent,
    OLShipmentDetailsComponent,
    OLShipmentDetails2Component,
    AirShipmentDetailsComponent,
    AirShipmentDetails2Component,
    AirShipmentSegmentComponent,
    OLShipmentSegmentComponent,
    ShipmentProductDlg,
    ProductSelectComponent,
    DemurrageConfigurationComponent,
    OrderSelectComponent,
    ShipmentNoteComponent,
    ShipmentNoteAddComponent,
    SaveSearchDialogComponent,
    SaveSearchBtnComponent,
    OceanShipmentsDetailComponent,
    OsExceptionComponent,
    ShipmentDetailsTabComponent,
    OceanShipmentUpdateComponent,
    SensorComponent,
    MilestonesAddUpdateComponent,
    ShipmentBingMapsComponent,
    ShipmentBingMapsDialogComponent,
    RoadShipmentDetailsComponent,
  ],
  imports: [
    CommonModule,
    AgmMarkerClustererModule,
    FontAwesomeModule,
    FlexLayoutModule,
    AgmCoreModule.forRoot({ apiKey: environment.mapKey }),
    MaterialModule,
    MatGridListModule,
    DpComponentsModule,
    DpPipesModule,
    InlineSVGModule,
    PdfViewerModule,
    NgxFileDropModule,
    DpDirectivesModule,
    NgxChartsModule,
    NgxCaptchaModule,
    MentionsModule,
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
    MdePopoverModule,
  ],
  exports: [
    NgxCaptchaModule,
    AgmCoreModule,
    CargoesFeaturesComponent,
    ContainerTrackingInfoComponent,
    VesselInfoComponent,
    VesselInfo2Component,
    ProductsTableComponent,
    ConfirmDialogComponent,
    DocumentPreviewDialogComponent,
    DocumentsUploadCardComponent,
    DocumentsUploadCardComponent2,
    DocumentUploadDialogComponent,
    CheckConsentStatusComponent,
    SharingLinkComponent,
    ShipmentTrackingInfoComponent,
    TrackingSummaryComponent,
    TrackingDetailsComponent,
    TrackingStepComponent,
    ShipmentMapComponent,
    TemperatureHumidityComponent,
    ContainerDetailsComponent,
    TrackingResultComponent,
    TrackingResultRoadTransportComponent,
    TrackingResultAirComponent,
    ShipmentSegmentComponent,
    DocumentsUploadCardPublicComponent,
    TrackingResultMblBkComponent,
    ContainerAccordionComponent,
    DocumentsUploadComponent,
    ReportErrorComponent,
    AirShipmentDetailsComponent,
    AirShipmentDetails2Component,
    AirShipmentSegmentComponent,
    OLShipmentSegmentComponent,
    TrackingResultOLComponent,
    OLShipmentDetailsComponent,
    OLShipmentDetails2Component,
    ShipmentProductDlg,
    ProductSelectComponent,
    DemurrageConfigurationComponent,
    OrderSelectComponent,
    ShipmentNoteComponent,
    ShipmentNoteAddComponent,
    SaveSearchDialogComponent,
    SaveSearchBtnComponent,
    TooltipModule,
    OceanShipmentsDetailComponent,
    OsExceptionComponent,
    ShipmentDetailsTabComponent,
    OceanShipmentUpdateComponent,
    SensorComponent,
    InlineSVGModule,
    MilestonesAddUpdateComponent,
    RoadShipmentDetailsComponent,
  ],
  providers: [
    {
      provide: LAZY_MAPS_API_CONFIG,
      useFactory: (init: AuthService) => ({ 
        region: init.currentOrganizationValue.countryCode,
        apiKey: environment.mapKey,
       }),
      deps: [AuthService],
    },
  ],
})
//todo: store feature module here, move lib module to shared.module, rename this one to featuresModule
export class SharedCommonModule {}
