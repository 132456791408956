import { Component, OnInit } from '@angular/core';
import { UIService } from '../shared/ui.service';
import { AuthService } from '../auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'environments/environment';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { Utility } from '@dp/utilities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'dp-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent implements OnInit {
  isExpanded = true;
  isHandset: boolean;
  isAuth: boolean;
  showSideNav: boolean;
  isFooterHide = true;
  SvgMap = SvgMap;
  svgAttributes = {
    width: 24,
    height: 24,
  };

  constructor(private uiService: UIService, private authService: AuthService, private router: Router) {
    window['dataLayer'] = window['dataLayer'] || [];
    const sidebarCollapse = Utility.getItem(environment.storage.sidebarCollapse, 'session');
    this.isExpanded = sidebarCollapse !== undefined ? !sidebarCollapse : this.isExpanded;
  }

  ngOnInit(): void {
    this.uiService.isHandsetChanged.pipe(untilDestroyed(this)).subscribe((result) => (this.isHandset = result));
    this.authService.currentUser.pipe(untilDestroyed(this)).subscribe((user) => {
      this.isAuth = this.authService.isAuth();
    });
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // event is an instance of NavigationEnd, get url!
        const url = event.urlAfterRedirects;
        const cleanUrl = url.split('?')[0];
        this.isFooterHide = environment.onFooterHideRoutes.includes(cleanUrl);
        const routes = environment.noHeaderRoutes as string[];
        if (routes.find((route) => url.indexOf(route) > 0)) {
          this.showSideNav = false;
        } else {
          this.showSideNav = true;
        }
      }
    });
  }

  toggleSideBar() {
    this.isExpanded = !this.isExpanded;
    Utility.setItem(environment.storage.sidebarCollapse, !this.isExpanded, 'session');
    this.trackEvent();
  }
  trackEvent() {
    window['dataLayer'].push({
      event: environment.gaEvents.names.navbar,
      eventDetail: {
        eventCategory: environment.gaEvents.categories.navBar,
        eventAction: environment.gaEvents.actions.hamburgerClick,
        isExpanded: this.isExpanded,
      },
    });
  }
}
