import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProgressService, UIService } from 'app/shared';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from 'app/settings/users/users.service';
import { finalize } from 'rxjs/operators';
import { dpAnimations } from '@dp/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionRequest, ApprovalStatusResponse } from '@dp/types';

@Component({
  selector: 'dp-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
  animations: dpAnimations,
})
export class NewUserComponent implements OnInit {
  email: string;
  action: string;
  actionCode: string;
  hasResult = false;
  error: HttpErrorResponse = null;
  actionMap = { accept: 'approved', decline: 'denied' };

  @ViewChild('contentZone') contentZone?: ElementRef;

  constructor(
    private progressService: ProgressService,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private uiService: UIService
  ) {}

  ngOnInit(): void {
    this.action = this.route.snapshot.paramMap.get('action');
    this.actionCode = this.route.snapshot.paramMap.get('actionCode');

    let progress = this.progressService.showProgress(this.contentZone);
    const payload = {
      action: this.action,
      actionCode: this.actionCode,
    };
     const actionRequest: ActionRequest = {
      payload: { action: this.action, actionCode: this.actionCode },
      approvalRequest: 'ORG_REQ'
    }
    this.usersService
      .setApprovalStatus(actionRequest)
      .pipe(
        finalize(() => {
          this.progressService.detach(progress);
        })
      )
      .subscribe(
        (result: ApprovalStatusResponse) => {
          this.hasResult = true;
          this.email = result['userEmail'];
        },
        (error) => {
          this.error = error;
          // this.uiService.showSnackbar("We can't process this right now. Please try again later.", null, {
          //   duration: environment.snackBarDuration.warning,
          //   panelClass: 'warn',
          // });
        }
      );
  }

  getTitle() {
    let title = this.action === 'accept' ? 'Approve an account' : 'Deny an account';
    return title;
  }
  getError() {
    let err = this.error?.status === 500 ? 'This action is not valid anymore!' : "We can't process this right now. Please try again later.";
    return err;
  }
}
