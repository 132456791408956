import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { environment } from 'environments/environment';
import { ShipmentMap, JourneyRoute } from 'app/shipments2/shipments2.model';
import { MapSpace } from 'app/shipments2/shipments3.model';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { ShipmentMapService } from './shipment-map.service';
import { MatDialog } from '@angular/material/dialog';
import { ShipmentBingMapsDialogComponent } from './shipment-bing-maps-dialog/shipment-bing-maps-dialog.component';

@Component({
  selector: 'dp-shipment-map',
  templateUrl: './shipment-map.component.html',
  styleUrls: ['./shipment-map.component.scss'],
})
export class ShipmentMapComponent implements OnInit {
  SvgMap = SvgMap;
  restriction = environment.map.restriction;
  @Input() shipmentDetail: MapSpace.ShipmentMap;
  @Input() isManualIntermodalLinkage: boolean = false;
  @Input() trackingType = '';
  // @Input() vessel: string;
  @Input() maxZoom = 12; //truck shipment need to set to 8
  locationName = '';
  showBingMaps = false;
  showBingMapsFullScreen = false;
  timer;
  openInfoWindow = false;
  shipmentCurrentLocation = {
    lat: 0,
    lon: 0,
    locationName: '',
    locationTimestamp: '',
  };
  firstMileConnection = [];
  lastMileConnection = [];
  stopSmall = {
    scaledSize: {
      width: 14,
      height: 14,
    },
    anchor: {
      x: 7,
      y: 7,
    },
  };
  stopSmallest = {
    scaledSize: {
      width: 2,
      height: 2,
    },
    anchor: {
      x: 1,
      y: 1,
    },
  };
  stopLarge = {
    scaledSize: {
      width: 20,
      height: 20,
    },
    anchor: {
      x: 10,
      y: 10,
    },
  };
  stopSmallComplete = {
    url: 'assets/svg/cityMarkerComplete.svg',
    ...this.stopSmall,
  };
  stopSmallIncomplete = {
    url: 'assets/svg/cityMarkerIncomplete.svg',
    ...this.stopSmall,
  };
  stopLargeComplete = {
    url: 'assets/svg/cityMarkerComplete.svg',
    ...this.stopLarge,
  };
  stopLargeIncomplete = {
    url: 'assets/svg/cityMarkerIncomplete.svg',
    ...this.stopLarge,
  };
  stopCurrent = {
    url: 'assets/svg/cityMarkerCurrent.svg',
    ...this.stopLarge,
  };
  currentLocation = {
    url: 'assets/svg/currentLocation.svg',
    ...this.stopLarge,
  };
  missingPath = {
    url: 'assets/svg/circle_missing.svg',
    ...this.stopSmallest,
  };
  aisCluster = {
    url: 'assets/svg/ais_cluster_ping.svg',
  };
  aisPings = {
    url: 'assets/svg/ais_pings.svg',
    scaledSize: {
      width: 8.5,
      height: 8.5,
    },
    anchor: {
      x: 5,
      y: 5,
    },
  };
  gpsPings = {
    url: 'assets/svg/ais_pings.svg',
    scaledSize: {
      width: 10,
      height: 10,
    },
  };
  actualRoute = {
    url: 'assets/svg/map_actual_line.svg',
  };
  expectedRoute = {
    url: 'assets/svg/map_expected_line.svg',
  };
  missingRoute = {
    url: 'assets/svg/map_missing_line.svg',
  };
  legendItems = [
    {
      title: 'INTERMODAL_SHIPMENT',
      data: [
        {
          icon: this.actualRoute.url,
          title: 'Actual Route',
          width: 28,
        },
        {
          icon: this.expectedRoute.url,
          title: 'Expected Route',
          width: 28,
        },
        {
          icon: this.missingRoute.url,
          title: 'Undetermined Expected Route',
          width: 28,
        },
        {
          icon: this.aisPings.url,
          title: 'AIS Pings',
          width: 10,
        },
      ],
    },
    {
      title: 'TRUCK_SHIPMENT',
      data: [
        {
          icon: this.expectedRoute.url,
          title: 'Expected Route',
          width: 28,
        },
        {
          icon: this.missingRoute.url,
          title: 'Undetermined Expected Route',
          width: 28,
        },
        {
          icon: this.aisPings.url,
          title: 'GPS Pings',
          width: 10,
        },
      ],
    },
  ];

  constructor(public shipmentMapService: ShipmentMapService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.shipmentDetail.journeyStops = this.shipmentDetail.journeyStops.filter((stop) => stop.lat && stop.lon);

    if (this.isManualIntermodalLinkage) {
      const routes = this.shipmentDetail.routes;
      this.firstMileConnection = this.getIntermodalOriginDestination(routes.shipToPort, routes.portToPort);
      this.lastMileConnection = this.getIntermodalOriginDestination(routes.portToPort, routes.shipToDestination);
    }
    const { lat, lon, locationLabel, locationTimestamp, vesselName } = this.shipmentDetail.shipmentLocation;
    this.shipmentCurrentLocation = { ...this.shipmentCurrentLocation, lat, lon, locationTimestamp };
    if (this.shipmentDetail.shipmentType === 'INTERMODAL_SHIPMENT') {
      this.shipmentCurrentLocation.locationName = 
        this.shipmentDetail.shipmentLocation.locationType === MapSpace.ShipmentLocationTypes.IN_PORT 
        ? locationLabel
        : 'Vessel: ' + (vesselName ? vesselName : 'Not Available');
    } else {
      this.shipmentCurrentLocation.locationName = locationLabel;
    }
  }

  isActualPathAvailable() {
    if (this.shipmentDetail.shipmentType === 'INTERMODAL_SHIPMENT') {
      const pastRoute = this.shipmentDetail.routes.actualPath['pastRoute'];
      const pastRouteLength = pastRoute.length;

      if (pastRouteLength) {
        const lastCoordinate = pastRoute[pastRouteLength - 1];
        const { lat, lon, locationName } = lastCoordinate[lastCoordinate.length - 1];
        this.shipmentCurrentLocation = { ...this.shipmentCurrentLocation, lat, lon };
        if (locationName) {
          this.shipmentCurrentLocation.locationName = locationName;
        }
        this.shipmentCurrentLocation.locationTimestamp = '';
        return true;
      }
    } else if (this.shipmentDetail.shipmentType === 'TRUCK_SHIPMENT') {
      return this.shipmentDetail.routes.actualPath['pastRoute'].length ? true : false;
    }
    return false;
  }

  cluster_road = {
    url: 'assets/svg/cluster_road.svg',
  }

  mapOptions = {
    styles: [
      {
        url: this.aisCluster.url,
        height: 12,
        width: 12,
        textSize: 0.1,
        anchorText: [6, 0],
      },
    ],
  };

  mapStyles = {
    styles: [
      {
        url: this.cluster_road.url,
        height: 30,
        width: 30,
        textSize: 15,
        anchorText: [6, 0],
      },
    ],
    calculator: (markers, numStyles) => {
      const index = Math.max(markers.length, numStyles);
      return {
        text: `${markers.length}`,
        index: index
      };
    }
  }

  markers = [];
  locations = [];

  ngOnChanges(changes: SimpleChanges) {
    if (this.shipmentDetail.shipmentType === "TRUCK_SHIPMENT") {
      const { shipmentDetail } = changes;
      if (shipmentDetail.currentValue.routes.actualPath['pastRoute'].length) {
        this.locations = shipmentDetail.currentValue.routes.actualPath['pastRoute'][0];
        this.updateMarkers();
      }
    } else if (this.shipmentDetail.shipmentType === "INTERMODAL_SHIPMENT") {
      const { shipmentDetail } = changes;
      if (shipmentDetail.currentValue.routes.actualPath['pastRoute'].length) {
        this.markers = shipmentDetail.currentValue.routes.actualPath['pastRoute'][0];
      }
    }
  }

  updateMarkers() {
    let duplicateLocation = [];

    this.locations.forEach(location => {
      let key = `${location.lat},${location.lon}`;
      if (duplicateLocation[key]) {
        duplicateLocation[key].count++;
      } else {
        duplicateLocation[key] = { count: 1, locations: [] };
      }
      duplicateLocation[key].locations.push(location);
    });

    for (let key in duplicateLocation) {
      const duplicate = duplicateLocation[key];
      const lastLocation = duplicate.locations.length - 1;
      this.markers.push({
        count: duplicate.count,
        coord:duplicate.locations[0],
        from: this.getTimestamp(duplicate.locations[0]?.timestamp),
        to: this.getTimestamp(duplicate.locations[lastLocation]?.timestamp),
      });
    }
    let pingNumber = 1;
    this.markers.forEach(marker => {
      const currentCount = marker.count;
      if(marker.count === 1) {
        marker.count = pingNumber.toString();
      } else {
        marker.count = pingNumber.toString() + " to " + (pingNumber + marker.count - 1).toString();
      }
      pingNumber = pingNumber + currentCount;
    });
  }

  getTimestamp(timestamp) {
    if (timestamp) {
      const utcDate = new Date(timestamp);
      let formattedDate = utcDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
      }).replace(/ /g, '/');

      const hours = utcDate.getUTCHours().toString().padStart(2, '0');
      const minutes = utcDate.getUTCMinutes().toString().padStart(2, '0');
      const amPm = +hours >=12 ? 'PM' : 'AM';
      formattedDate = formattedDate + ` ${(+hours % 12) || 12}:${minutes} ${amPm}`;
      return formattedDate;
    }
    return 'No Data';
  }

  showTimestamp(marker) {
    if (marker?.count.includes('to') && marker?.from !== 'No Data') {
      return `Duration: (${marker?.from} - ${marker?.to})`;
    }
    return marker?.from;
  }

  onMouseOver(infoWindow, gm, locationName: string) {
    this.locationName = locationName;
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow?.open();
  }

  //Function to return the Destination of firstRoute and Origin of secondRoute.
  getIntermodalOriginDestination(firstRoute, secondRoute) {
    let destination;
    let origin;
    
    if (firstRoute['futureRoute']?.length) {
      const futureRoute = firstRoute['futureRoute']?.[0];
      const index = futureRoute?.length - 1;
      destination = futureRoute?.[index];
    } else if (firstRoute['pastRoute']?.length) {
      const pastRoute = firstRoute['pastRoute']?.[0];
      const index = pastRoute?.length - 1;
      destination = pastRoute?.[index];
    } else {
      return;
    }

    if (secondRoute['pastRoute']?.length) {
      const pastRoute = secondRoute['pastRoute']?.[0];
      origin = pastRoute?.[0];
    } else if (secondRoute['futureRoute']?.length) {
      const futureRoute = secondRoute['futureRoute']?.[0];
      origin = futureRoute?.[0];
    } else {
      return;
    }

    return [
      {
        lat: destination?.lat,
        lon: destination?.lon,
      },
      {
        lat: origin?.lat,
        lon: origin?.lon,
      },
    ];
  }

  getFitBounds(routeKey: string): boolean {
    const result =
      this.shipmentDetail.mapFocusMode === MapSpace.MapFocusModes.TOTAL_ROUTE ||
      (this.shipmentDetail.mapFocusMode === MapSpace.MapFocusModes.SHIP_TO_PORT && routeKey === 'shipToPort') ||
      (this.shipmentDetail.mapFocusMode === MapSpace.MapFocusModes.SHIP_TO_DESTINATION && routeKey === 'shipToDestination')
        ? true
        : false;
    //console.log('FitBounds ', routeKey, result);
    return result;
  }

  getFitBoundsForStop(stop: MapSpace.JourneyStop): boolean {
    const origins = [
      MapSpace.SHIPMENT_LOCATION_TYPES.ORIGIN,
      MapSpace.SHIPMENT_LOCATION_TYPES.ORIGIN_HUB,
      MapSpace.SHIPMENT_LOCATION_TYPES.ORIGIN_PORT,
    ];
    const destinations = [
      MapSpace.SHIPMENT_LOCATION_TYPES.DESTINATION,
      MapSpace.SHIPMENT_LOCATION_TYPES.DESTINATION_HUB,
      MapSpace.SHIPMENT_LOCATION_TYPES.DESTINATION_PORT,
    ];
    const result =
      this.shipmentDetail.shipmentType === MapSpace.ShipmentTypes.TRUCK_SHIPMENT ||
      this.shipmentDetail.mapFocusMode === MapSpace.MapFocusModes.TOTAL_ROUTE ||
      (this.shipmentDetail.mapFocusMode === MapSpace.MapFocusModes.SHIP_TO_PORT && origins.includes(stop.type)) ||
      (this.shipmentDetail.mapFocusMode === MapSpace.MapFocusModes.SHIP_TO_DESTINATION && destinations.includes(stop.type))
        ? true
        : false;
    return result;
  }

  getPossibleRouteMiddle(stops: MapSpace.Stop[], pointType: 'lat' | 'lon'): number {
    if (stops.length === 1) {
      return stops[0][pointType];
    }
    if (pointType === 'lat') {
      const result = (stops[0][pointType] + stops[stops.length - 1][pointType]) / 2;
      return result;
    } else {
      const distance = stops[0][pointType] - stops[stops.length - 1][pointType];
      let result = (stops[0][pointType] + stops[stops.length - 1][pointType]) / 2;
      if (distance > 180 || distance < -180) {
        result += 180;
        if (result > 180) {
          result -= 360;
        }
      }
      return result;
    }
  }

  noData() {
    return !this.shipmentDetail?.hasValidData;
  }

  isGoogleMapAvailable() {
    this.timer && clearTimeout(this.timer);
    const val = !(typeof google === 'object' && typeof google.maps === 'object');
    if (this.showBingMaps !== val) {
      this.timer = setTimeout(() => {
        this.showBingMaps = val;
      }, 1000);
    }
  }

  fullScreen() {
    this.showBingMapsFullScreen = true;
    const dlg = this.dialog.open(ShipmentBingMapsDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      hasBackdrop: false,
      panelClass: 'dp-shipment-bing-maps-dialog',
      data: {
        shipmentDetail: this.shipmentDetail,
        trackingType: this.trackingType,
      },
    });

    dlg.afterClosed().subscribe(() => {
      this.showBingMapsFullScreen = false;
    });
  }
}
