import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { IDocument } from 'app/shipments2/shipments2.model';
import { AuthService } from 'app/auth/auth.service';
import { EntityType } from '@dp/types';
@Component({
  selector: 'dp-document-dialog',
  templateUrl: './document-preview-dialog.component.html',
  styleUrls: ['./document-preview-dialog.component.scss'],
})
export class DocumentPreviewDialogComponent implements OnInit {
  documentUri: string;
  loading: boolean = true;
  fileSrc: {
    url: string;
    httpHeaders?: Object;
  };
  document: IDocument;
  documentName: string;

  constructor(
    public dialogRef: MatDialogRef<DocumentPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService
  ) {}

  ngOnInit() {
    console.log('preview init ...', this.data);

    this.document = this.data.document as IDocument;
    this.documentName = this.data.documentName;
    this.loading = true;

    let url = environment.rootUrl;

    if (this.data.tokenId) {
      url += `files/sharedDocument?token=${this.data.tokenId}&documentId=${this.document.id}`;
    } else if (this.document.entityType === EntityType.INVOICE) {
      url += `invoices/download/${this.document.id}`;
    } else {
      url += environment.urls.files_document + '/' + this.document.id;

      if (this.data.documentVersion) {
        url += `?version=${this.data.documentVersion}`;
      } else if (this.document.version) {
        url += `?version=${this.document.version}`;
      }
    }

    if (typeof this.document.documentExtension === 'string' && this.document.documentExtension.toLowerCase() === 'pdf') {
      const impersonated = this.authService.currentUserValue?.impersonated ? true : false;
      this.fileSrc = {
        url,
        httpHeaders: {
          'x-dpw-apikey': environment.apiValue,
          'X-DPW-User-Token': this.authService.currentUserValue.token,
          [environment.HEADER.IMPERSONATED_TOKEN]: impersonated,
        },
      };
    } else {
      this.fileSrc = {
        url,
      };
    }
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(true);
  }

  onImageLoaded() {
    this.loading = false;
  }
}
