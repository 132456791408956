export const ROUTE_PATH = {
  OCEAN_TRACKING: '/ocean-shipments/tracking',
  OCEAN_TRACK_BY_GROUP: '/ocean-shipments/tracking-by-group',
  ROAD_TRACKING: '/road-shipments/tracking',
  AIR_TRACKING: '/air-shipments/tracking',
  OL_TRACKING: '/outsourced-logistics/tracking',
  VESSEL_SCHEDULE: '/vessel-schedules',
  FLIGHT_SCHEDULE: '/flight-schedules',
  LOCATIONS: '/locations',
  ADD_SINGLE_ROAD_SHIPMENT: 'add-shipments/TRUCK_SHIPMENT',
};

export const ROUTE_REFERER = {
  DASHBOARD_OCEAN: 'DashOean',
  DASHBOARD_ROAD: 'DashRoad',
  DASHBOARD_AIR: 'DashAir',
  DASHBOARD_SUMMARY: 'DashSummary',
  CALENDAR_OCEAN: 'CalOcean',
  CALENDAR_ROAD: 'CalRoad',
  CALENDAR_AIR: 'CalAir',
  UPLOAD_HISTORY: 'uploadHistory',
};

const migrateRouteBasePath = 'cargoes/flow/';
export const MIGRATE_ROUTES = {
  OCEAN_TRACKING: `${migrateRouteBasePath}shipment/ocean`,
  VESSEL_SCHEDULE: `${migrateRouteBasePath}schedules/vessel`,
  FLIGHT_SCHEDULE: `${migrateRouteBasePath}schedules/flight`,
  LOCATIONS: `${migrateRouteBasePath}locations`,
  ADD_SINGLE_ROAD_SHIPMENT: `${migrateRouteBasePath}add-shipment/truck/add`
};
