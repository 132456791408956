import { INotificationItem } from 'app/navigation/header/notification/notification.model';
import { Country } from '@dp/services/static-data.model';

export enum FILTER_FLAG {
  ALL = 'All',
  FLAGGED = 'FLAGGED',
  NOTFLAGGED = 'NOT FLAGGED',
}

export enum TRACKING_STATE {
  ALL = 'All',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
}

export interface PageData<T> {
  total: number;
  data: T[];
}

export enum Keys {
  DELETE = 8,
  ENTER = 13,
  ESCAPE = 27,
  ARROW_LEFT = 37,
  ARROW_UP = 38,
  ARROW_RIGHT = 39,
  ARROW_DOWN = 40,
}

export interface Pagination {
  pageSize: number;
  currentPage: number;
  pageSizes?: number[];
  pageSizeOptions?: number[];
  total?: number;
}

export interface ToggleFlagInfo {
  id: number;
  flag: boolean;
}

export interface ToggleNotificationInfo {
  id: number;
  subscribed: boolean;
}

export interface Couple {
  parent: number;
  child: number;
}

export interface ListEntity {
  key: string;
  displayText: string;
  children?: ListEntity[];
}

export interface BasicSuccessResponse {
  success: boolean;
}

export enum ACTION_TYPES {
  SHIPMENT,
  SHIPMENT_DETAIL,
}
export interface ChainedAction {
  current: ACTION_TYPES;
  next: ACTION_TYPES;
  payload: Object;
  child?: ChainedAction;
}

//TODO: remove all other Address interface
export interface Address {
  streetLine1: string;
  streetLine2?: string;
  streetLine3?: string;
  city: string;
  stateProv: string;
  postalCode: string;
  countryCode: string;
}

export interface InviteUserResult {
  email: string;
  status: string;
  message: string;
}

export interface ShipmentDropdownFilters {
  carriers?: string[];
  destinationPorts?: string[];
  destinationHubs?: string[]; //portsOfArrival?: string[];
  originHubs?: string[]; //portsOfDeparture?: string[];
  originCountryCode?: string[];
  destinationCountryCode?: string[];
  originCountry?: Country[];
  destinationCountry?: Country[];
  incoterm?: string[];
  originPorts?: string[];
  consignee?: string[];
  consignor?: string[];
  tags?: string[];
  originAddresses?: string[];
  destinationAddresses?: string[];
}

// single container tracking
export interface TrackingEvent {
  id: number;
  eventType: string;
  eventCode: string;
  carrier: string;
  estimateTime: string;
  actualTime: string;
  location: string;
  dpwFlag: boolean;
  isCurrent: boolean;
  containerId: number;
}
export interface Segment {
  vessel: string;
  voyage: string;
  portOfLoading: string;
  portOfDischarge: string;
  carrier: string;
  etd: string;
  atd: string;
  eta: string;
  ata: string;
}

export interface ContainersTracking {
  containerNumber: string;
  containerType: string;
  containerSize: string;
  carrier: string;
  segments: Segment[];
  trackingEvents: TrackingEvent[];
  origin: string;
  destination: string;
  etd: string;
  atd: string;
  eta: string;
  ata: string;
}

export interface Tab {
  id: string;
  label: string;
}

export interface dlTable {
  header: string[];
  rows: string[][];
}

export interface dlItem {
  dt: string;
  dd: string;
  isCustom?: boolean;
  customKey?: string;
  context?: string;
  table?: dlTable;
}

export interface IDbFilter {
  shipmentIds?: number[];
  shipmentNumber?: string;
  status?: string;
  exceptionSeverity?: number;
}
export interface IDbInfo {
  locations: any[];
  shipments: IDbShipment[];
  exceptions: Exception[];
  actions?: any[];
}
export interface IDbShipment {
  id: number;
  shipmentNumber: string;
  shipmentName: string;
  shipmentReference: string;
  shipmentType: string;
  shipmentContent: string;
  serviceMode: string;
  oceanCarrier: string;
  carrierInfo: string;
  departureDate: string;
  departureDateType: string;
  arrivalDate: string;
  arrivalDateType: string;
  portOfDeparture: string;
  departureDpwFlag: boolean;
  arrivalDpwFlag: boolean;
  portOfArrival: string;
  totalContainers: number;
  activeContainers: number;
  flagged: boolean;
  bolNumber: null;
  vessel: string;
  voyage: string;
  status: string;
  subStatus1: string;
  subStatus2: string;
  exceptionMessage: string;
  exceptionSeverity: number;
  etd: string;
  atd: string;
  eta: string;
  ata: null;
  incoterm: string;
  instructionsCutOffDate: string;
  trackingTrace: TimelineSpot[];
  shipmentProcedure: string;
  updatedAt?: string;
  originalEta?: string;
  matchingContainerNumbers?: number[];
}
export interface Exception {
  exception: string;
  severity: number;
  message: string;
  delay: string;
  shipmentIds?: number[] | null;
  updatedAt: string;
}

export interface TimelineSpot {
  title: string;
  isCurrent: boolean;
  message: string;
}

export interface Tag {
  id?: number;
  name: string;
}

export interface TagSubscription {
  name: string;
  id: number;
  notificationSubscriptionId: number;
}

export interface ShippingLane {
  type: 'INTERMODAL_SHIPMENT' | 'TRUCKING_SHIPMENT';
  origin: string;
  destination: string;
  originCode: string;
  destinationCode: string;
  carrier: string;
  notificationSubscriptionId: number;
}
export interface ProductSubscribed {
  id?: number;
  productNumber?: string;
  productCode: string;
  description: string;
  hsCode: string;
}

export interface ItemSelectable {
  name: string;
  //selected?: boolean;
  subItems?: INotificationItem[];
}

export interface tableColumnInfo {
  name?: string;
  allowChange?: boolean;
  order?: number;
  show?: boolean;
  isEnabled?: boolean;
}

export interface tableGroupInfo {
  name: string;
  columns: { [key: string]: tableColumnInfo };
}

export interface tableInfo {
  [groupKey: string]: tableGroupInfo;
}

export interface JsonValueStringArray {
  [key: string]: string[];
}
export interface JsonValueJsonArray {
  [key: string]: Object[];
}

export interface OrderBy {
  _sort: string;
  _order: string;
}

export enum EntityType {
  CONTAINER = 'CONTAINER',
  OCEAN_SHIPMENT = 'OCEAN_SHIPMENT',
  TRACKING_SHIPMENT = 'TRACKING_SHIPMENT',
  SHIPMENT_GROUPS = 'SHIPMENT_GROUPS',
  INVOICE = 'INVOICE',
}

export interface PagedItems<T> {
  total: number;
  items: T[];
}

export interface payloadColumnInfo {
  action?: string;
  actionCode?: string;
}

export interface ActionRequest {
  payload: payloadColumnInfo;
  approvalRequest: string;
}

export interface ApprovalStatusResponse {
  success: boolean;
  userEmail?: string;
}
// export enum ReportTrackingErrors {
//   OutOfOrder = 'Tracking events are out of order',
//   WrongCarrierOrMBL = 'Track with the wrong carrier or MBL #',
//   ShipmentNotFound = 'Shipment is not found (Untrackable)',
//   IncorrectOrMissing = 'The tracking events or locations are incorrect or missing',
//   WrongTransportType = 'Wrong transport type (E.g. truck, rail, vessel, barge)',
//   MapLocationWrong = 'Map locations are wrong',
//   EventsNotExcluded = 'The container events from an earlier journey were not excluded',
//   Others = 'Others',
// }

export interface IColumnSetting {
  key: string;
  isEnabled: boolean;
  allowedChange: boolean;
}

export interface IVSColumnPreferences {
  columns: IColumnSetting[];
}

export interface IFSColumnPreferences {
  columns: IColumnSetting[];
}

export enum SHIPMENT_TEMPERATURE_STATES {
  // used for the temperature indicators, from API
  HIGH = 'HIGH',
  NORMAL = 'NORMAL',
  LOW = 'LOW',
}
export enum SHIPMENT_HUMIDITY_STATES {
  // used for the temperature indicators, from API
  HIGH = 'HIGH',
  NORMAL = 'NORMAL',
  LOW = 'LOW',
}
export interface TemperatureInfo {
  minTolerantTemperature?: string;
  maxTolerantTemperature?: string;
  lastTemperature: string;
  lastAbnormalTemperature: string;
  uom: string;
  lastAbnormalTemperatureState?: SHIPMENT_TEMPERATURE_STATES | string;
  lastTemperatureState: SHIPMENT_TEMPERATURE_STATES | string;
  lastTemperatureRecordedAt: string;
  lastAbnormalTemperatureRecordedAt: string;
  temperatureHistory?: TemperatureHistory[];
}
export interface HumidityInfo {
  minTolerantHumidity?: number;
  maxTolerantHumidity?: number;
  lastHumidity: number;
  lastAbnormalHumidity: number;
  uom: string;
  lastAbnormalHumidityState?: SHIPMENT_HUMIDITY_STATES | string;
  lastHumidityState: SHIPMENT_HUMIDITY_STATES | string;
  lastHumidityRecordedAt: string;
  lastAbnormalHumidityRecordedAt: string;
  humidityHistory?: HumidityHistory[];
}

export interface TemperatureHistory {
  recordedAt: string;
  temperature: number;
}

export interface HumidityHistory {
  recordedAt: string;
  humidity: number;
}

export interface Device {
  id: number;
  deviceId: string;
  iotServiceProvider: string;
}

// order of the enum is used for the order of the chart in the sensor tab
export enum SensorTypes {
  temperature = 'temperature',
  product_temperature = 'product temperature',
  humidity = 'humidity',
  light = 'light',
  shock = 'shock',
  location = 'location',
}
export enum SensorValueState {
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export interface SensorApiItem {
  sensorName: SensorTypes | string;
  data: SensorData[];
}

export interface SensorUiItem {
  // key is the device id
  [key: string]: SensorTypeData;
}
export type SensorTypeData = {
  [key in SensorTypes]?: SensorData;
};

export interface SensorData {
  deviceId: string;
  recordsHistory: SensorRecord[];
  maxThresholdValue?: number;
  minThresholdValue?: number;
  lastRecordedValue: string;
  lastValueRecordedAt?: string;
  lastAbnormalRecordedValue?: number;
  lastAbnormalValueRecordedAt: string;

  // data patched
  lastAbnormalRecord?: SensorRecord;
  lastValueState?: SensorValueState;
  uom?: string;
}

export interface SensorRecord {
  value: number;
  recordedAt: string;
  lat: number;
  long: number;
}
export interface HumidityHistory {
  recordedAt: string;
  humidity: number;
}
export enum ShipmentUploadTypes {
  CONTAINER,
  BK,
  MBL,
  VESSEL,
}

export interface IGeoFence {
  organization_id: number;
  property_key: string;
  property_value: number;
}
