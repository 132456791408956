<div *ngIf="errorText" class="mt-12 p-24">
  <mat-error>{{errorText}}</mat-error>
</div>

<section class="tracking-info p-28">

  <div class="mt-12" *ngIf="payload" class="relative">
    <dp-air-shipment-details [payload]="payload"></dp-air-shipment-details>
    <dp-svg class="reportIcon" tooltip="Report error" [src]="SvgMap.report" w="20" (click)="reportError()"></dp-svg>
  </div>

  <div *ngIf="!showReportError" @slideInLeft>
    <mat-tab-group style="overflow-y: auto;" (selectedTabChange)="selectedTabChange($event)">

      <!-- Tracking Tab -->
      <mat-tab [label]="tabs[0]">
        <section class="tab-content">

          <dp-air-shipment-details-2 [transportJourneys]="transportJourneys"></dp-air-shipment-details-2>

          <ng-container *ngIf="transportJourneys?.portToPort?.segmentData; let segmentData">
            <dp-air-shipment-segment [segmentData]="segmentData" heading="Shipping Segments"></dp-air-shipment-segment>
          </ng-container>

          <ng-container *ngIf="shipmentMap">
            <h3>Tracking Events</h3>
            <dp-shipment-map [shipmentDetail]="shipmentMap" [maxZoom]="12" [trackingType]="payload.trackingType"></dp-shipment-map>
          </ng-container>

          <div *ngIf="transportJourneys?.portToPort?.statusSteps?.steps; let steps" class="mt-12 pl-12">
            <dp-tracking-step [steps]="steps"></dp-tracking-step>
          </div>
        </section>
      </mat-tab>

      <!-- Documents Tab -->
      <mat-tab *ngIf="showDocumentsInSharedLinks">
        <ng-template mat-tab-label>
          {{ tabs[1] }}
          <ng-container *ngIf="payload?.documents?.length !== 0">
            <div [ngClass]="{'active': selectedTabIndex === 1, 'miniFab' : payload?.documents?.length}">
              {{ payload?.documents?.length }}</div>
          </ng-container>
        </ng-template>


        <section class="tab-content">
          <!-- No document cases -->
          <div *ngIf="payload.documents.length == 0" fxLayout="column" fxLayoutAlign="center center">
            <h2 class="text-muted">No documents available!</h2>
          </div>

          <ng-container *ngIf="payload.documents.length > 0">
            <dp-documents-upload-card-public *ngFor="let document of payload.documents" [document]="document"
              [entityType]="EntityType.TRACKING_SHIPMENT" [tokenId]="tokenId">
            </dp-documents-upload-card-public>
          </ng-container>

        </section>
      </mat-tab>

      <mat-tab *ngIf="showException && !isEmpty(payload.exceptions)">
        <ng-template mat-tab-label>
          Exceptions
          <div *ngIf="exceptionCount" class="miniFab" [ngClass]="{'active': selectedTabIndex === 2}">
            {{ exceptionCount }}</div>
        </ng-template>
        <section class="tab-content mt-8">
          <dp-os-exception [shipmentDetail]="payload" [steps]="payload.exceptions.steps"></dp-os-exception>
        </section>
      </mat-tab>
      
    </mat-tab-group>
  </div>

  <dp-report-error *ngIf="showReportError" [isEmail]="true" @slideInRight (goBack)="close()" (close)="close()" [data]="payload"
    [commentOnly]="true">
  </dp-report-error>

</section>