<ng-container *ngIf="chatbotService.state$ | async as state">
  <div class="chatbox-wrapper mat-elevation-z12" *ngIf="state.showChatBox" @onOff>
    <button mat-mini-fab class="easy-button tiny" class="close" (click)="toggleChat()">
      <mat-icon class="material-icons">close</mat-icon>
    </button>

    <div class="chatbox-header d-flex align-items-start">
      <div class="position-relative">
        <button disabled mat-mini-fab class="chatbot-logo">
          <img src="assets/cargoflow-new-square.svg" width="20px">
        </button>
        <span class="circle"></span>
      </div>

      <div>
        <div fxLayout="row">
          <h2>
            Cargoes Flow GPT
          </h2>
          <span class="warning-dark-text ml-12 px-8 beta-text">Beta</span>
        </div>

        <p class="pl-20 mt-4">Online</p>
      </div>

    </div>
    <div class="chatbox-body">
      <!-- start-up -->
      <div class="sampleQueries" *ngIf="showSampleQueries">
        <div class="m-auto text-center" style="width: 80%;">
          <h3 class="heading">Start a Conversation!</h3>
          <p class="text-muted">To get started, choose a chat from the list or initiate a new one.</p>
          <p class="text-muted">Here are some recommendations:</p>
        </div>

        <p class="query" [ngClass]="{'chosen': q==chatInput}" *ngFor="let item of sampleQueries" (click)="selectQuery(item.value)"
          [innerHTML]="item.q">
        </p>
      </div>

      <ng-container *ngFor="let chatMessage of chatMessages">
        <dp-chatbot-message [chatMessage]="chatMessage"></dp-chatbot-message>
      </ng-container>
      <ng-container *ngIf="waitingResponse">
        <dp-chatbot-message [chatMessage]="{user: 'chatbot'}">
          <div class="flex flex-column">
            <div class="dot-flashing my-4"></div>
            <span class="text-muted">Generating response for you...</span>
          </div>

        </dp-chatbot-message>
      </ng-container>
      <div id="bottomSection"></div>
    </div>

    <div class="chat-input mt-auto">
      <form class="position-relative" autocomplete="off" (ngSubmit)="onSubmit(chatInput)">
        <textarea #chatInputElement [disabled]="waitingResponse" required type="text" id="chat-input" placeholder="Ask me anything"
          [(ngModel)]="chatInput" (keyup.enter)="onKeyEnter(chatInput)" focusable></textarea>
        <button mat-icon-button class="chat-submit" [disabled]="!prepareQuery(chatInput)">
          <mat-icon [style.color]="prepareQuery(chatInput) ? 'var(--enabled-color)' : 'var(--disabled-color)'">send</mat-icon>
        </button>

      </form>
    </div>

  </div>

  <div id="chat-bubble" class="btn btn-raised" [ngClass]="{'shrink': !ctoText}" (click)="toggleChat();" *ngIf="state.showChatBubble">
    <mat-icon class="material-icons chat-icon">chat</mat-icon>
    <h3 class="m-0 pl-16" *ngIf="ctoText">{{ctoText}}</h3>
  </div>
</ng-container>