import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { dpAnimations } from '@dp/animations';

@Component({
  selector: 'dp-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: dpAnimations
})
export class RegisterComponent implements OnInit {
  isBusy = false;
  returnUrl: string;
  error = '';
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (this.authService.currentUserValue !== null) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  onSubmit(form: NgForm) {
    this.beforeSubmit();
    this.authService
      .registerUser({
        email: form.value.email,
        password: form.value.password
      })
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
          // this.login(form);
        },
        error => {
          this.error = error.error.message;
          this.isBusy = false;
        }
      );
  }

  // TODO: temporary code
  login(form: NgForm) {
    this.authService
      .login({
        email: form.value.email,
        password: form.value.password
      })
      .subscribe(_ => this.router.navigate([this.returnUrl]));
  }

  beforeSubmit() {
    this.isBusy = true;
    this.error = '';
  }
}
