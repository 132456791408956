<form [formGroup]="formGroup" class="py-16 mb-4 easy-form">
  <!-- <p>Mode: {{currentMode}}</p> -->

  <mat-error *ngIf="formGroup.invalid">
  </mat-error>
  <!-- read-only mode -->
  <ng-container>
    <section class="d-flex justify-content-between align-items-center py-8">
      <div fxLayout="row" fxLayoutAlign="start start">
        <label for="port" class="components-table-heading w-200">
          {{ getPortName(formGroup.value) }}
        </label>
        <label for="port" class="components-table-heading w-300">
          {{ getCarrierName(formGroup.value.carrier) }}
        </label>
      </div>
      <div>

        <button mat-flat-button class="easy-button small mr-20 updateBtn" (click)="dispatchAction('UPDATE')" [disabled]="!isFormValid()"
          *ngIf="isEditing">
          <mat-icon>save</mat-icon>
          <span>Update</span>
        </button>

        <button mat-flat-button class="easy-button secondary small cancelBtn" (click)="cancelEditing()" *ngIf="isEditing">
          <mat-icon>clear</mat-icon>
          <span>Cancel</span>
        </button>

        <!-- TODO: add confirm delete dialog -->
        <button mat-flat-button class="easy-button secondary small deleteBtn" (click)="confirmDelete('DELETE')"
          *ngIf="formGroup.value.id && !isEditing && !isDefaultMetric(formGroup) && options.isAdmin">
          <mat-icon>clear</mat-icon>
          <span>Delete</span>
        </button>
      </div>
    </section>

    <section *ngIf="formGroup.errors">
      <mat-error *ngIf="formGroup.errors['invalidRange']">
        Medium severity value must be less than High severity value.
      </mat-error>
    </section>
    <section *ngIf="mediumSeverityToleranceSecsCtrl.errors">
      <mat-error *ngIf="mediumSeverityToleranceSecsCtrl.errors['min'] || mediumSeverityToleranceSecsCtrl.errors['max']">
        Medium severity value is invalid.
      </mat-error>
    </section>
    <section *ngIf="highSeverityToleranceSecsCtrl.errors">
      <mat-error *ngIf="highSeverityToleranceSecsCtrl.errors['min'] || highSeverityToleranceSecsCtrl.errors['max']">
        High severity value is invalid.
      </mat-error>
    </section>

    <section class="body-regular">
      <div fxLayout="row" fxLayoutAlign="start start">
        <div class="w-200 withNumInput">
          Medium severity
        </div>
        <div class="w-200 withNumInput">
          Storage time more than
        </div>
        <div class="w-100">
          <mat-form-field appearance="outline" class="w-60">
            <mat-label>Days</mat-label>
            <input matInput type="number" min="1" max="60" formControlName="mediumSeverityToleranceSecs" [readonly]="!options.isAdmin">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <div class="w-200 withNumInput">
          High severity
        </div>
        <div class="w-200 withNumInput">
          Storage time more than
        </div>
        <div class="w-100">
          <mat-form-field appearance="outline" class="w-60">
            <mat-label>Days</mat-label>
            <input matInput type="number" min="1" max="60" class="form-control" formControlName="highSeverityToleranceSecs"
              [readonly]="!options.isAdmin">
          </mat-form-field>
          <!-- <mat-error *ngIf="highSeverityToleranceSecsCtrl.invalid">Valid value range 1-60</mat-error> -->
        </div>
      </div>

      <ng-container *ngIf="isDemurrageDetentionEnabled">
        <div fxLayout="row" fxLayoutAlign="start start">
          <div class="w-200 withNumInput d-flex align-items-center">
            <span>
              Demurrage Free Days
            </span>
            <dp-svg [src]="SvgMap.infoExclamation" w="18" placement="bottom"
             tooltip="Number of days a container can remain at the port without incurring demurrage charges."></dp-svg>
          </div>
          <div class="w-200 withNumInput">
          </div>
          <div class="w-100">
            <mat-form-field appearance="outline" class="w-60">
              <mat-label>Days</mat-label>
              <input matInput type="number" min="1" class="form-control" formControlName="demurrageFreeDays" [readonly]="!options.isAdmin">
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start">
          <div class="w-200 withNumInput d-flex align-items-center">
            <span>
              Detention Free Days
            </span>
            <dp-svg [src]="SvgMap.infoExclamation" w="18" placement="bottom"
             tooltip="Number of days a container can remain out of the port before gated in without incurring detention charges."></dp-svg>
          </div>
          <div class="w-200 withNumInput">
          </div>
          <div class="w-100">
            <mat-form-field appearance="outline" class="w-60">
              <mat-label>Days</mat-label>
              <input matInput type="number" min="1" class="form-control" formControlName="detentionFreeDays" [readonly]="!options.isAdmin">
            </mat-form-field>
          </div>
        </div>
      </ng-container>

    </section>
  </ng-container>

</form>